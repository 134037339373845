import { Component, computed, inject, input, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { EditorService } from "../services/editor.service"
import { DecimalPipe } from "@angular/common"
import { SaveIconComponent } from "../../shared/save-icon.component"
import { TimelineService } from "../../pages/timeline/timeline.service"

@Component({
  imports: [
    FormsModule,
    DecimalPipe,
    SaveIconComponent,
  ],
  standalone: true,
  template: `
    @if (horizontal()) {
      <div>
        Horizontal Position
        ({{ position() / 30 | number: "1.0-0" }}% from left):
        {{ position() }}/{{ pageWidth }}px
        <e2e-save-icon [modelChange]="modelChange()" />
      </div>
      <div style="padding: 0 15px">
        <!--
                <e2e-number
         [min]="0"
                  [max]="2900"
                  size="small"
                  [value]="position()"
                  (changes)="change($event)"
                />
        -->
        <input
          type="range"
          min="0"
          [max]="pageWidth"
          class="range range-secondary w-full"
          [ngModel]="position()"
          (ngModelChange)="change($event)"
        />
      </div>
    }
    @if (vertical()) {
      <div>
        Vertical Position
        ({{ position() / 10 | number: "1.0-0" }}% from top):
        {{ position() }}/{{ pageHeight }}px
        <e2e-save-icon [modelChange]="modelChange()" />
      </div>
      <div style="padding: 0 15px">
        <input
          type="range"
          min="0"
          [max]="pageHeight"
          class="range range-secondary w-full"
          [ngModel]="position()"
          (ngModelChange)="change($event)"
        />
      </div>
    }
  `,
  selector: "e2e-editor-layout-position",
})
export class EditorLayoutPositionComponent {
  private editorService = inject(EditorService)
  private timelineService = inject(TimelineService)

  axis = input.required<"horizontal" | "vertical">()
  horizontal = computed(() => this.axis() === "horizontal")
  vertical = computed(() => this.axis() === "vertical")
  pageHeight = this.timelineService.pageHeight
  pageWidth = this.timelineService.pageWidth

  position = computed(() => {
    let position = 0
    if (this.horizontal()) position = this.editorService.editorContent()?.settings.position.left.px || 0
    if (this.vertical()) position = this.editorService.editorContent()?.settings.position.top.px || 0
    return position
  })

  modelChange = signal<number | undefined>(undefined)

  change(position: number) {
    this.modelChange.set(Date.now())
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        settings: {
          ...content.settings,
          position: {
            ...content.settings.position,
            left: {
              ...content.settings.position.left,
              px: this.horizontal() ? position : content.settings.position.left.px,
            },
            top: {
              ...content.settings.position.top,
              px: this.vertical() ? position : content.settings.position.top.px,
            },
          },
        },
      })
    }
  }
}
