import { effect, inject, Injectable, signal, untracked } from "@angular/core"
import { doc, Firestore, onSnapshot, setDoc } from "@angular/fire/firestore"
import { TourDoc, TourItem, tourItemsMock } from "./tour.model"
import { debounceSignal } from "@ddtmm/angular-signal-generators"

@Injectable({
  providedIn: "root"
})
export class TourService {
  private firestore = inject(Firestore)

  private _showTour = signal(false)
  showTour = this._showTour.asReadonly()

  private _tourLoaded = signal(false)
  tourLoaded = this._tourLoaded.asReadonly()
  private tourDocs = signal<TourDoc[]>([])
  _tourItems = signal<TourItem[]>([])
  tourItems = this._tourItems.asReadonly()
  tourItems_map = signal<Map<number, TourItem>>(new Map())
  tourItemsQueue = debounceSignal<TourItem[]>([], 3000)

  constructor() {
    this.subscribeToTourDoc()
    // this.initializeDatabase()
    effect(() => {
      const tourItems_array = this.tourItemsQueue()
      if (tourItems_array.length) {
        untracked(() => this.saveTourItems(tourItems_array))
      }
    })
  }

  setShowTour(showTour: boolean) {
    this._showTour.set(showTour)
  }

  subscribeToTourDoc() {
    return onSnapshot(doc(this.firestore, "content-groups/tour"),
      (snapshot) => {
        const tourDoc = snapshot.data() as TourDoc
        this.tourDocs.set(tourDoc && [tourDoc] || [])
        if (tourDoc) {
          this._tourItems.set(tourDoc.items)
          this.tourItems_map.set(new Map(
            tourDoc.items.map((item, index) => [index, item])
          ))
        }
        this._tourLoaded.set(!!tourDoc)
      })
  }

  initializeDatabase() {
    setDoc(doc(this.firestore, "content-groups/tour"), { items: tourItemsMock }, { merge: false })
      .then(() => {

      })
  }

  saveTourItems(items: TourItem[]) {
    setDoc(doc(this.firestore, "content-groups/tour"), { items }, { merge: false })
      .then(() => {

      })
  }

}
