import { Component, computed, inject, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { EditorService } from "../services/editor.service"
import { SaveIconComponent } from "../../shared/save-icon.component"
import { DecimalPipe } from "@angular/common"
import { UiCheckboxComponent } from "ui/checkbox"
import { UiInheritDirective } from "ui/inherit"
import { TimelineService } from "../../pages/timeline/timeline.service"

@Component({
  imports: [
    FormsModule,
    SaveIconComponent,
    UiInheritDirective,
    DecimalPipe,
    UiCheckboxComponent,
  ],
  standalone: true,
  template: `
    <div>
      Width: {{ disabled() ? "disabled" : (width() | number: "1.0-0") + "px" }}
      <e2e-save-icon [modelChange]="modelChangeWidth()"/>
    </div>
    <div style="padding: 0 15px">
      <input
        type="range"
        min="0"
        [max]="pageWidth"
        class="range range-secondary w-full"
        [ngModel]="width()"
        (ngModelChange)="changeWidth($event)"
      />
    </div>
    <div class="row">
      <lib-ui-checkbox
        name="disableWidth"
        label="Disable Width Slider (useful for SVGs)"
        identifier="disableWidth"
        [selected]="disabled()"
        (changes)="changeDisabled($event)"
      />
      <e2e-save-icon [modelChange]="modelChangeDisable()"/>
    </div>
  `,
  selector: "e2e-editor-layout-width",
})
export class EditorLayoutWidthComponent {
  private editorService = inject(EditorService)
  private timelineService = inject(TimelineService)

  pageWidth = this.timelineService.pageWidth
  width = computed(() => this.editorService.editorContent()?.settings.width.px || 0)
  disabled = computed(() => this.width() === -1)

  modelChangeDisable = signal<number | undefined>(undefined)
  modelChangeWidth = signal<number | undefined>(undefined)

  changeWidth(px: number) {
    this.modelChangeWidth.set(Date.now())
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        settings: {
          ...content.settings,
          width: {
            ...content.settings.width,
            px,
          },
        },
      })
    }
  }

  changeDisabled(disable: boolean) {
    this.modelChangeDisable.set(Date.now())
    this.modelChangeWidth.set(Date.now())
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        settings: {
          ...content.settings,
          width: {
            ...content.settings.width,
            px: disable ? -1 : 200,
          },
        },
      })
    }
  }
}
