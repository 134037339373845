import { inject, Injectable } from "@angular/core"
import { StorageService } from "@shared"

export enum SvgGraphics {
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
  A6 = "A6",
  A7 = "A7",
  A8 = "A8",
  A9 = "A9",
  A10 = "A10",
  A11 = "A11",
  A12 = "A12",
  A13 = "A13",
  A14 = "A14",
  A15 = "A15",
  A16 = "A16",
  A17 = "A17",
  A18 = "A18",
  A19 = "A19",
  A20 = "A20",
  A21 = "A21",
  A22 = "A22",
  A23 = "A23",
  A24 = "A24",
  A25 = "A25",
  A26 = "A26",
  A27 = "A27",
  A28 = "A28",
  A29 = "A29",
  A30 = "A30",
  A31 = "A31",
  A32 = "A32",
  A33 = "A33",
  A34 = "A34",
  A35 = "A35",
  A36 = "A36",
  A37 = "A37",
  A38 = "A38",
  D1 = "D1",
  D2 = "D2",
  A41 = "A41",
  A42 = "A42",
  A43 = "A43",
  A44 = "A44",
  A45 = "A45",
  A46 = "A46",
  A47 = "A47",
  A48 = "A48",
  A49 = "A49",
  A50 = "A50",
  A51 = "A51",
  A52 = "A52",
  A53 = "A53",
  A54 = "A54",
  A55 = "A55",
  A56 = "A56",
  A57 = "A57",
  A58 = "A58",
  A59 = "A59",
  A60 = "A60",
  A61 = "A61",
  A62 = "A62",
  A63 = "A63",
  A64 = "A64",
  A65 = "A65",
  A66 = "A66",
  A67 = "A67",
}

export type SvgGraphic = {
  label: SvgGraphics
  fileName: string
}

@Injectable({
  providedIn: "root"
})
export class TimelineSvgGraphicsService {
  private storageService = inject(StorageService)

  style="display: flex; flex-wrap: wrap; gap: 10px; align-items: center"
  svgGraphics: SvgGraphic[] = [
    { label: SvgGraphics.A1, fileName: "/assets/svg-graphics/arrow1.svg" },
    { label: SvgGraphics.A2, fileName: "/assets/svg-graphics/arrow2.svg" },
    { label: SvgGraphics.A3, fileName: "/assets/svg-graphics/arrow3.svg" },
    { label: SvgGraphics.A4, fileName: "/assets/svg-graphics/arrow4.svg" },
    { label: SvgGraphics.A5, fileName: "/assets/svg-graphics/arrow5.svg" },
    { label: SvgGraphics.A6, fileName: "/assets/svg-graphics/arrow6.svg" },
    { label: SvgGraphics.A7, fileName: "/assets/svg-graphics/arrow7.svg" },
    { label: SvgGraphics.A8, fileName: "/assets/svg-graphics/arrow8.svg" },
    { label: SvgGraphics.A9, fileName: "/assets/svg-graphics/arrow9.svg" },
    { label: SvgGraphics.A10, fileName: "/assets/svg-graphics/arrow10.svg" },
    { label: SvgGraphics.A11, fileName: "/assets/svg-graphics/arrow11.svg" },
    { label: SvgGraphics.A12, fileName: "/assets/svg-graphics/arrow12.svg" },
    { label: SvgGraphics.A13, fileName: "/assets/svg-graphics/arrow13.svg" },
    { label: SvgGraphics.A14, fileName: "/assets/svg-graphics/arrow14.svg" },
    { label: SvgGraphics.A15, fileName: "/assets/svg-graphics/arrow15.svg" },
    { label: SvgGraphics.A16, fileName: "/assets/svg-graphics/arrow16.svg" },
    { label: SvgGraphics.A17, fileName: "/assets/svg-graphics/arrow17.svg" },
    { label: SvgGraphics.A18, fileName: "/assets/svg-graphics/arrow18.svg" },
    { label: SvgGraphics.A19, fileName: "/assets/svg-graphics/arrow19.svg" },
    { label: SvgGraphics.A20, fileName: "/assets/svg-graphics/arrow20.svg" },
    { label: SvgGraphics.A21, fileName: "/assets/svg-graphics/arrow21.svg" },
    { label: SvgGraphics.A22, fileName: "/assets/svg-graphics/arrow22.svg" },
    { label: SvgGraphics.A23, fileName: "/assets/svg-graphics/arrow23.svg" },
    { label: SvgGraphics.A24, fileName: "/assets/svg-graphics/arrow24.svg" },
    { label: SvgGraphics.A25, fileName: "/assets/svg-graphics/arrow25.svg" },
    { label: SvgGraphics.A26, fileName: "/assets/svg-graphics/arrow26.svg" },
    { label: SvgGraphics.A27, fileName: "/assets/svg-graphics/arrow27.svg" },
    { label: SvgGraphics.A28, fileName: "/assets/svg-graphics/arrow28.svg" },
    { label: SvgGraphics.A29, fileName: "/assets/svg-graphics/arrow29.svg" },
    { label: SvgGraphics.A30, fileName: "/assets/svg-graphics/arrow30.svg" },
    { label: SvgGraphics.A31, fileName: "/assets/svg-graphics/arrow31.svg" },
    { label: SvgGraphics.A32, fileName: "/assets/svg-graphics/arrow32.svg" },
    { label: SvgGraphics.A33, fileName: "/assets/svg-graphics/arrow33.svg" },
    { label: SvgGraphics.A34, fileName: "/assets/svg-graphics/arrow34.svg" },
    { label: SvgGraphics.A35, fileName: "/assets/svg-graphics/arrow35.svg" },
    { label: SvgGraphics.A36, fileName: "/assets/svg-graphics/arrow36.svg" },
    { label: SvgGraphics.A37, fileName: "/assets/svg-graphics/arrow37.svg" },
    { label: SvgGraphics.A38, fileName: "/assets/svg-graphics/arrow38.svg" },
    { label: SvgGraphics.D1, fileName: "/assets/svg-graphics/arrow39-dashed1.svg" },
    { label: SvgGraphics.D2, fileName: "/assets/svg-graphics/arrow40-dashed2.svg" },
    { label: SvgGraphics.A41, fileName: "/assets/svg-graphics/arrow41.svg" },
    { label: SvgGraphics.A42, fileName: "/assets/svg-graphics/arrow42.svg" },
    { label: SvgGraphics.A43, fileName: "/assets/svg-graphics/arrow43.svg" },
    { label: SvgGraphics.A44, fileName: "/assets/svg-graphics/arrow44.svg" },
    { label: SvgGraphics.A45, fileName: "/assets/svg-graphics/arrow45.svg" },
    { label: SvgGraphics.A46, fileName: "/assets/svg-graphics/arrow46.svg" },
    { label: SvgGraphics.A47, fileName: "/assets/svg-graphics/arrow47.svg" },
    { label: SvgGraphics.A48, fileName: "/assets/svg-graphics/arrow48.svg" },
    { label: SvgGraphics.A49, fileName: "/assets/svg-graphics/arrow49.svg" },
    { label: SvgGraphics.A50, fileName: "/assets/svg-graphics/arrow50.svg" },
    { label: SvgGraphics.A51, fileName: "/assets/svg-graphics/arrow51.svg" },
    { label: SvgGraphics.A52, fileName: "/assets/svg-graphics/arrow52.svg" },
    { label: SvgGraphics.A53, fileName: "/assets/svg-graphics/arrow53.svg" },
    { label: SvgGraphics.A54, fileName: "/assets/svg-graphics/arrow54.svg" },
    { label: SvgGraphics.A55, fileName: "/assets/svg-graphics/arrow55.svg" },
    { label: SvgGraphics.A56, fileName: "/assets/svg-graphics/arrow56.svg" },
    { label: SvgGraphics.A57, fileName: "/assets/svg-graphics/arrow57.svg" },
    { label: SvgGraphics.A58, fileName: "/assets/svg-graphics/arrow58.svg" },
    { label: SvgGraphics.A59, fileName: "/assets/svg-graphics/arrow59.svg" },
    { label: SvgGraphics.A60, fileName: "/assets/svg-graphics/arrow60.svg" },
    { label: SvgGraphics.A61, fileName: "/assets/svg-graphics/arrow61.svg" },
    { label: SvgGraphics.A62, fileName: "/assets/svg-graphics/arrow62.svg" },
    { label: SvgGraphics.A63, fileName: "/assets/svg-graphics/arrow63.svg" },
    { label: SvgGraphics.A64, fileName: "/assets/svg-graphics/arrow64.svg" },
    { label: SvgGraphics.A65, fileName: "/assets/svg-graphics/arrow65.svg" },
    { label: SvgGraphics.A66, fileName: "/assets/svg-graphics/arrow66.svg" },
    { label: SvgGraphics.A67, fileName: "/assets/svg-graphics/arrow67.svg" },
  ]

  svgGraphic(label: SvgGraphics | null) {
    return this.svgGraphics.find(svgGraphic => svgGraphic.label === label)?.fileName
  }

}

