import {
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  input,
  OnDestroy,
  Output,
  Renderer2,
  signal,
  viewChild,
} from "@angular/core"
import { environment } from "../../../environments/environment"
import { toObservable } from "@angular/core/rxjs-interop"
import { filter, tap } from "rxjs/operators"
import { AsyncPipe } from "@angular/common"
import { combineLatest, map } from "rxjs"
import { AudioStatus, TourItem } from "./tour.model"

@Component({
  imports: [AsyncPipe],
  standalone: true,
  template: `
    <!--
        @if (item$ | async) {} doesn't control any view rendering
        Its purpose is to ensure the item$ observable is subscribed and processed
    -->
    @if (item$ | async) {
    }
    <audio
      #audioElement
      controls="controls"
      (playing)="setAudioStatus($event.type)"
      (pause)="setAudioStatus($event.type)"
      (ended)="nextItem()"
    >
      Your browser does not support the <code>audio</code> tour.
    </audio>
  `,
  selector: "e2e-tour-audio-player",
})
export class TourAudioPlayerComponent implements OnDestroy {
  private renderer2 = inject(Renderer2)

  interval = input(0)
  item = input<TourItem>()
  private elementRef = viewChild<ElementRef<HTMLAudioElement>>("audioElement")

  @Output() changeAudioStatus = new EventEmitter<AudioStatus>()
  @Output() changeToNextItem = new EventEmitter<void>()
  @Output() changeToNextSegment = new EventEmitter<void>()

  item$ = combineLatest([toObservable(this.item), toObservable(this.elementRef)]).pipe(
    map(
      ([item, audioElement]) =>
        [item, audioElement?.nativeElement] as [TourItem | undefined, HTMLAudioElement | undefined],
    ),
    filter((args): args is [TourItem, HTMLAudioElement] => Boolean(args[0] && args[1])),
    tap(([item, nativeElement]) => this.load(item, nativeElement)),
  )

  audioStatus = signal<string>("pause")
  audioStatusMessage = computed(() => (this.audioStatus() === "playing" ? "Pause the Tour" : "Resume the Tour"))

  ngOnDestroy() {
    this.elementRef()?.nativeElement.removeEventListener("timeupdate", this.timeUpdateListener)
  }

  nextItem() {
    this.changeToNextItem.emit()
  }

  nextSegment() {
    this.changeToNextSegment.emit()
  }

  setAudioStatus(audioStatus: string) {
    switch (audioStatus) {
      case "playing":
      case "pause":
        this.changeAudioStatus.emit(audioStatus as AudioStatus)
    }
  }

  pause() {
    this.elementRef()?.nativeElement.pause()
  }

  play() {
    this.elementRef()?.nativeElement.play()
  }

  load(item: TourItem, nativeElement: HTMLAudioElement) {
    nativeElement.pause()
    nativeElement.removeEventListener("timeupdate", this.timeUpdateListener)
    const audioFilePath = this.audioFilePath(item)
    if (audioFilePath) {
      this.renderer2.setAttribute(nativeElement, "src", audioFilePath)
      this.renderer2.setAttribute(nativeElement, "type", "audio/mp3")
      nativeElement.load()
      nativeElement
        .play()
        .then(() => {
          nativeElement.addEventListener("timeupdate", this.timeUpdateListener)
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  audioFilePath(item: TourItem) {
    return (
      "https://storage.googleapis.com/" +
        environment.firebaseConfig.storageBucket +
        "/tour-audio/" +
        item.id.toLowerCase() +
        ".mp3" || ""
    )
  }

  timeUpdateListener = () => {
    const nativeElement = this.elementRef()?.nativeElement
    if (nativeElement && this.interval() && nativeElement.currentTime >= this.interval()) {
      this.nextSegment()
    }
  }
}
