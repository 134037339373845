import { Injectable, signal } from "@angular/core"

@Injectable({
  providedIn: "root"
})
export class UiAlertService {
  show = signal(false)
  contentLeft = signal("")
  contentRight = signal("")

}
