import { Feature } from "geojson"

export interface StoryFeature extends Feature {
  properties: StoryProperties
}

export type StoryProperties = {
  authorUserName: string
  storyId: string
  text: string
}

export interface RegionFeature extends Feature {
  properties: RegionProperties
}

export type RegionProperties = {
  "1b"?: string, // class and occupation
  "1c"?: string, // foreign families (nationality)
  "1d"?: string, // negro infiltration
  "1e"?: string, // shifting or infiltration
  "8"?: string, // represents the description
  "9"?: string, // represents the name that HOLC gave this region
  "population.decreasing"?: string,
  "population.increasing"?: string,
  "population.static"?: string,
  all_fields?: string,
  area?: number,
  area_id?: number,
  area_number?: string,
  average_age?: string,
  average_age_other_type?: string,
  bounds?: number[][],
  buildings_type?: string,
  buildings_type_other_type?: string,
  category_id?: number,
  city?: string,
  city_id?: number,
  clarifying_remarks?: string,
  commercial?: boolean,
  construction?: string,
  construction_other_type?: string,
  date?: string,
  fill?: string,
  foreign_born_nationality?: string,
  foreign_born_percent?: string,
  form_id?: number,
  grade?: string,
  holc_grade?: string,
  holc_id?: string,
  home_ownership?: string,
  home_ownership_other_type?: string,
  industrial?: boolean,
  infiltration_of?: string,
  label?: string,
  label_coords?: number[],
  location?: string,
  mortagage_funds?: string,
  name?: string,
  negro_percent?: string,
  new_construction_amount_last_year?: string,
  new_construction_how_selling?: string,
  new_construction_types?: string,
  occupation_or_type?: string,
  occupancy?: string,
  occupancy_other_type?: string,
  overhang_holc?: string,
  overhang_institutions?: string,
  percent_other_type?: string,
  percent_predominating?: string,
  predicted_price_trend?: string,
  predicted_price_trend_other_type?: string,
  predicted_rent_trend?: string,
  predicted_rent_trend_other_type?: string,
  price_range_dollars_1935?: string,
  price_range_dollars_1935_other_type?: string,
  price_range_dollars_1937?: string,
  price_range_dollars_1937_other_type?: string,
  price_range_dollars_last_year?: string,
  price_range_dollars_last_year_other_type?: string,
  price_range_percent_1937?: string,
  price_range_percent_1937_other_type?: string,
  price_range_percent_last_year?: string,
  price_range_percent_last_year_other_type?: string,
  repair?: string,
  repair_other_type?: string,
  rental_demand_up_to?: string,
  rental_demand_up_to_other_type?: string,
  rent_range_dollars_1935?: string,
  rent_range_dollars_1935_other_type?: string,
  rent_range_dollars_1937?: string,
  rent_range_dollars_1937_other_type?: string,
  rent_range_dollars_last_year?: string,
  rent_range_dollars_last_year_other_type?: string,
  rent_range_percent_1937?: string,
  rent_range_percent_1937_other_type?: string,
  rent_range_percent_last_year?: string,
  rent_range_percent_last_year_other_type?: string,
  residential?: boolean,
  sales_demand_up_to?: string,
  sales_demand_up_to_other_type?: string,
  sales_holc?: string,
  sales_institutions?: string,
  selected_fields?: string,
  security_grade?: string,
  sheets?: number,
  state?: string,
  tax_rate_193X?: string,
  tax_rate_dollars?: string,
  name_of_city?: string,
  description_of_terrain?: string,
  favorable_influences?: string,
  detrimental_influences?: string,
  estimated_annual_family_income?: string,
  negro_yes_or_no?: string,
  relief_families?: string,
  population_increasing?: string,
  population_decreasing?: string,
  price_range_dollars_1929?: string,
  price_predominating_1929?: string,
  rent_range_dollars_1929?: string,
  rent_predominating_1929?: string,
  middle_year?: string,
  price_range_dollars_middle_year?: string,
  price_predominating_dollars_middle_year?: string,
  price_range_percent_middle_year?: string,
  rent_range_middle_year?: string,
  rent_predominating_middle_year?: string,
  rent_range_percent_middle_year?: string,
  last_year?: string,
  price_predominating_dollars_last_year?: string,
  rent_range_last_year?: string,
  rent_predominating_last_year?: string,
  peak_sales_year?: string,
  peak_sales_percent?: string,
  peak_rental_year?: string,
  peak_rental_percent?: string,
  dwelling_units?: string,
  sales_demand_characterization?: string,
  sales_demand_amount?: string,
  sales_demand_activity?: string,
  rental_demand_characterization?: string,
  rental_demand_amount?: string,
  rental_demand_activity?: string,
  availability_of_mortgage_funds_home_purchase?: string,
  availability_of_mortgage_funds_home_building?: string,
  trend_of_desirability?: string,
  information_obtained_from?: string,
  information_obtained_month_and_day?: string,
  information_obtained_193x?: string,
}
