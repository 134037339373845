export type TourDoc = {
  items: TourItem[]
}

export enum AudioStatus {
  playing = "playing",
  pause = "pause"
}

export enum TourItemId {
  INTRO_R1 = "INTRO_R1",
  INTRO_R2 = "INTRO_R2",
  S1_R1 = "S1_R1",
  S1_R2 = "S1_R2",
  S1_R3 = "S1_R3",
  S1_R4 = "S1_R4",
  S1_R5 = "S1_R5",
  S1_R6 = "S1_R6",
  S2_R1 = "S2_R1",
  S2_R2 = "S2_R2",
  S2_R3 = "S2_R3",
  S2_R4 = "S2_R4",
  S3_R1 = "S3_R1",
  S3_R2 = "S3_R2",
  S3_R3 = "S3_R3",
  S3_R4 = "S3_R4",
  S3_R5 = "S3_R5",
  S3_R6 = "S3_R6",
  S3_R7 = "S3_R7",
  S3_R8 = "S3_R8",
  S3_R9 = "S3_R9",
  S3_R10 = "S3_R10",
  S4_R1 = "S4_R1",
  S5_R1 = "S5_R1",
  S5_R2 = "S5_R2",
}

export type TourItem = {
  id: TourItemId
  fileName?: string
  sectionTitle: string
  title: string
  segments: {
    description: string
    timestamp: number
    section: string
    anchor: string
    contentId: string
    zoomLevel: number
    offsetX: number
    offsetY: number
  }[]
}

export enum SectionTitle {
  INTRO = "Welcome to Undesign the Redline",
  S1 = "Section 1, What is Redlining?",
  S2 = "Section 2, Local maps",
  S3 = "Section 3 Timeline",
  S4 = "Section 4 Local Stories",
  S5 = "Section 5 Undesign",
}

export const tourItemsMock: TourItem[] = [
  {
    id: TourItemId.INTRO_R1,
    sectionTitle: SectionTitle.INTRO,
    title: "This is a virtual version of a traveling exhibit",
    segments: []
  },
  {
    id: TourItemId.INTRO_R2,
    sectionTitle: SectionTitle.INTRO,
    title: "Learn more",
    segments: []
  },
  {
    id: TourItemId.S1_R1,
    sectionTitle: SectionTitle.S1,
    title: "Back to the 1930s",
    segments: [
      {
        description: "Credit system for neighborhoods",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S1_R2,
    sectionTitle: SectionTitle.S1,
    title: "Red areas were cut off",
    segments: [
      {
        description: "Vibrant neighborhoods declined",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S1_R3,
    sectionTitle: SectionTitle.S1,
    title: "Why? \"Negro Infiltration\"",
    segments: []
  },
  {
    id: TourItemId.S1_R4,
    sectionTitle: SectionTitle.S1,
    title: "Meanwhile green areas \"homogeneous\" largely in suburbs",
    segments: [
      {
        description: "Wealthier, whiter suburbs, concentrated poverty in cities, mostly people of color",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S1_R5,
    sectionTitle: SectionTitle.S1,
    title: "Fast forward to today - different systems for different people",
    segments: [
      {
        description: "This is why we think redlining is so important to understand, how do we go from explicit jim crow era racism, to structural racism",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
      {
        description: "Redlining is part of what designed racism and inequality into our cities and systems, it has never been undone - we still live in the world redlining created.",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S1_R6,
    sectionTitle: SectionTitle.S1,
    title: "Interact prompt – click on the other zones and the 'impacts for today' to learn",
    segments: []
  },
  {
    id: TourItemId.S2_R1,
    sectionTitle: SectionTitle.S2,
    title: "Explore the local map (location - at map)",
    segments: [

      {
        description: "SEGMENT 1",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },

      {
        description: "SEGMENT 2",
        timestamp: 15,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },

    ]
  },
  {
    id: TourItemId.S2_R2,
    sectionTitle: SectionTitle.S2,
    title: "Underwriting Manual (location - underwriting manual image) prompt to interact? Or this pop out just goes on its own",
    segments: [
      {
        description: "Can discuss this more for clarity",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S2_R3,
    sectionTitle: SectionTitle.S2,
    title: "NAREB quote (location - nareb quote)",
    segments: []
  },
  {
    id: TourItemId.S2_R4,
    sectionTitle: SectionTitle.S2,
    title: "So we’ve learned about redlining (Redlining timeline? - animation to s3?)",
    segments: []
  },
  {
    id: TourItemId.S3_R1,
    sectionTitle: SectionTitle.S3,
    title: "How to read the timeline (zoomed out to full timeline)",
    segments: []
  },
  {
    id: TourItemId.S3_R2,
    sectionTitle: SectionTitle.S3,
    title: "We begin the timeline… (Section 1, top)",
    segments: [
      {
        description: "Zoom to american progress",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
      {
        description: "Zoom to reconstruction",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
      {
        description: "Zoom back out / jim crow",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S3_R3,
    sectionTitle: SectionTitle.S3,
    title: "When we look at the bottom up side of the timeline (Section 1, bottom)",
    segments: []
  },
  {
    id: TourItemId.S3_R4,
    sectionTitle: SectionTitle.S3,
    title: "So this brings us to redlining (Section 2 top)",
    segments: [
      {
        description: "Zoom to blockbusting quote",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S3_R5,
    sectionTitle: SectionTitle.S3,
    title: "Slum clearance and urban renewal (Section 3 top, zoomed to topic region)",
    segments: []
  },
  {
    id: TourItemId.S3_R6,
    sectionTitle: SectionTitle.S3,
    title: "Budget Crises and planned shrinkage",
    segments: [
      {
        description: "Zoom to planned shrinkage",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
      {
        description: "Zoom to epidemics",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S3_R7,
    sectionTitle: SectionTitle.S3,
    title: "Now we’re really in a crisis (Section 3 bottom)",
    segments: [
      {
        description: "Zoom up to Section 4 top, broken windows area",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S3_R8,
    sectionTitle: SectionTitle.S3,
    title: "this brings us to today (Section 4 top)",
    segments: []
  },
  {
    id: TourItemId.S3_R9,
    sectionTitle: SectionTitle.S3,
    title: "Lessons (zoom back out to full timeline)",
    segments: []
  },
  {
    id: TourItemId.S3_R10,
    sectionTitle: SectionTitle.S3,
    title: "Encourage Interaction and next",
    segments: []
  },
  {
    id: TourItemId.S4_R1,
    sectionTitle: SectionTitle.S4,
    title: "Introduction to Section 4 and how to interact",
    segments: []
  },
  {
    id: TourItemId.S5_R1,
    sectionTitle: SectionTitle.S5,
    title: "How do we undesign?",
    segments: [
      {
        description: "Zoom to board 1",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
      {
        description: "Zoom to board 2",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
      {
        description: "Zoom to board 3",
        timestamp: 0,
        section: "",
        anchor: "",
        contentId: "",
        zoomLevel: 1,
        offsetX: 0,
        offsetY: 0
      },
    ]
  },
  {
    id: TourItemId.S5_R2,
    sectionTitle: SectionTitle.S5,
    title: "Add your ideas and stay connected",
    segments: []
  },
]
