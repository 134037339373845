import { Injectable } from "@angular/core"
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Observable, throwError } from "rxjs"
import { catchError } from "rxjs/operators"
import { retryBackoff } from "backoff-rxjs"

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler):
    Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        retryBackoff({
          initialInterval: 10,
          maxRetries: 3,
          maxInterval: 1000,
          resetOnSuccess: true,
          shouldRetry: (error: any) => {
            if (error.status === 400) {
              console.log("Not retrying for 400 Bad Request errors.")
              return false
            }
            if (error.status === 401) {
              console.log("Not retrying for 401 Not authorized.")
              return false
            }
            if (error.status === 500) {
              console.log("Not retrying for 500 Internal Server Error.")
              return false
            }
            console.log(error)
            return true
          },
          backoffDelay: (iteration, initialInterval) => iteration * 1000 + initialInterval
        }),
        catchError(error => {
          console.log("Error handled by HTTP interceptor...")
          return throwError(error)
          // return throwError(() => this.generalErrorHandler(error, caught))
          // return throwError(() => {
          //   console.log("Error rethrown by HTTP interceptor")
          //   return error
          // })
        })
      )
  }

  generalErrorHandler(error: any, caught: Observable<any>): Observable<any> {
    switch (error.error.status) {
      case "INVALID_TOKEN":
      case "MAX_TOKEN_ISSUE_REACHED":
        console.log('token has expired')
      // this.logout()
    }
    return error
  }

}

export const HttpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
}
