import { Component, inject } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"
import { PageToolbarService } from "../../page-toolbar.service"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    <div
      #pageToolbarPlaceholder
      style="width: 100%"
      [style.height.px]="pageToolbarHeight()"
    ></div>
    <div class="column bg-base-100 w-full">
      <div>
        <img [ngSrc]="'/assets/pages/section5/section5-1.png'" alt="image" fill style="position: relative">
      </div>

      <div>
        <img [ngSrc]="'/assets/pages/section5/section5-2.png'" alt="image" fill style="position: relative">
      </div>

      <div>
        <img [ngSrc]="'/assets/pages/section5/section5-3.png'" alt="image" fill style="position: relative">
      </div>
    </div>
  `,
  selector: "e2e-section5-view",
})
export class Section5ViewComponent {
  private pageToolbarService = inject(PageToolbarService)
  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight
}
