import { Component, inject } from "@angular/core"
import { UiAlertComponent, UiAlertService } from "ui/alert"
import { UiToastService } from "./ui-toast.service"

@Component({
  imports: [
    UiAlertComponent,
  ],
  standalone: true,
  template: `
    @if (show()) {
      <div class="toast toast-center toast-top absolute" style="z-index: 11">
        <lib-ui-alert>
          <ng-content select="alert-content" ngProjectAs="content-left">
            {{ alertContent() }}
          </ng-content>
        </lib-ui-alert>
      </div>
    }
  `,
  selector: "lib-ui-toast",
})
export class UiToastComponent {
  private uiToastService = inject(UiToastService)
  private uiAlertService = inject(UiAlertService)

  show = this.uiToastService.show
  alertContent = this.uiToastService.alertContent

  constructor() {
    this.uiAlertService.show.set(true)
  }

}
