import { Component, computed, effect, inject, OnInit, signal, untracked } from "@angular/core"
import { LoginComponent } from "../login/login.component"
import { ProfileStatusComponent } from "./profile-status.component"
import { RouterLink } from "@angular/router"
import { ProfileComponent } from "../profile/profile.component"
import { MyContentRowsComponent } from "./my-content-rows.component"
import { ModerationRowsComponent } from "../moderation/moderation-rows.component"
import { ContentManagerRowsComponent } from "../content-manager/content-manager-rows.component"
import { RouteService } from "../../services/route.service"
import { LoginProfileWizardStatusComponent } from "../login/login-profile-wizard-status.component"
import { ProfileService } from "../profile/profile.service"
import { DataAccessFirebaseAuthService } from "data-access/firebase-auth"
import { UiDialogComponent } from "ui/dialog"
import { PageToolbarService } from "../page-toolbar.service"
import { UiIconComponent } from "ui/icon"
import { ProfileProgress } from "../profile/profile.model"
import { ProfileBirthYearComponent } from "../profile/fields/profile-birth-year.component"
import { ProfileHomeLocationComponent } from "../profile/fields/profile-home-location.component"
import { ProfileUserNameComponent } from "../profile/fields/profile-user-name.component"
import { ProfileTitleComponent } from "../profile/fields/profile-title.component"
import { ProfileEditorService } from "../profile/profile-editor.service"
import { FeatureSharedLoginComponent } from "feature/shared/login"

@Component({
  imports: [
    LoginComponent,
    ProfileStatusComponent,
    ProfileComponent,
    MyContentRowsComponent,
    ModerationRowsComponent,
    ContentManagerRowsComponent,
    UiIconComponent,
    LoginProfileWizardStatusComponent,
    UiDialogComponent,
    RouterLink,
    ProfileBirthYearComponent,
    ProfileHomeLocationComponent,
    ProfileUserNameComponent,
    ProfileTitleComponent,
    FeatureSharedLoginComponent,
  ],
  standalone: true,
  template: `
    <lib-ui-dialog (cancelAndClose)="close()" position="top" [offset]="pageToolbarHeight() + 10">

      <div ngProjectAs="title">
        @if (!wizardEnabled() && this.loggedIn()) {
          <div style="padding: 10px 0 0">
            <e2e-profile-status (cancelAndClose)="close()" style="flex: 1"/>
          </div>
        }
      </div>

      <div ngProjectAs="content">

        @if (wizardEnabled() && this.loggedIn()) {
          <div class="column">
            <div>
              Thank you for your interest in the Undesign the Redline Community.
              Please answer these quick questions to create an account and provide basic information for your profile.
              After you do this, you will be able to submit stories.
            </div>
            @if (stepNumber() === 1) {
              <e2e-profile-user-name [autosave]="false"/>
            } @else if (stepNumber() === 2) {
              <e2e-profile-home-location [autosave]="false"/>
            } @else if (stepNumber() === 3) {
              <e2e-profile-birth-year [autosave]="false"/>
            } @else if (stepNumber() === 4) {
              <e2e-profile-title [autosave]="false"/>
            }
          </div>
        }
        @if (!wizardEnabled() && tabIndex() === 0 && !this.loggedIn()) {
          <lib-feature-shared-login/>
        }
        @if (!wizardEnabled() && tabIndex() !== 0 && this.loggedIn()) {
          <div role="tablist" class="tabs tabs-lifted">
            <a
              role="tab"
              class="font-bold"
              [class]="tabIndex() === 1 ? 'tab tab-active' : 'tab'"
              style="white-space: nowrap"
              [routerLink]="[]"
              [queryParams]="queryParamsList().myAccountProfile"
            >
              Profile
            </a>
            <div class="tab-content bg-base-100 border-base-300 rounded-box p-6">
              @defer (when tabIndex() === 1 && userId()) {
                <e2e-profile/>
              }
            </div>

            <a
              role="tab"
              class="font-bold"
              [class]="tabIndex() === 2 ? 'tab tab-active' : 'tab'"
              style="white-space: nowrap"
              [routerLink]="[]"
              [queryParams]="queryParamsList().myAccountContent"
            >
              My Content
            </a>
            <div class="tab-content bg-base-100 border-base-300 rounded-box p-6">
              @defer (when tabIndex() === 2 && userId()) {
                <e2e-my-content-rows/>
              }
            </div>
          </div>
        }
      </div>

      <div ngProjectAs="footer">
        @if (wizardEnabled() && this.loggedIn()) {
          <div class="column center" style="align-items: center">
            <button
              class="btn"
              (click)="saveProfile()"
            >
              Next
            </button>
            @if (stepNumber() < 5) {
              <div>
                Step
                {{ stepNumber() }}
                of 4
              </div>
            }
          </div>
        }
      </div>

    </lib-ui-dialog>
  `,
  selector: "e2e-my-account",
})
export class MyAccountComponent implements OnInit {
  private routeService = inject(RouteService)
  private profileService = inject(ProfileService)
  private authStateService = inject(DataAccessFirebaseAuthService)
  private pageToolbarService = inject(PageToolbarService)
  private profileEditorService = inject(ProfileEditorService)

  queryParamsList = this.routeService.queryParamsList
  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight

  userId = this.authStateService.userId
  tabIndex = this.routeService.myAccountTabIndex
  section = this.routeService.section

  profileIncomplete = computed(() =>
    this.profileService.profileStatus().progress === ProfileProgress.INCOMPLETE,
  )
  profileComplete = computed(() =>
    this.profileService.profileStatus().progress !== ProfileProgress.INCOMPLETE,
  )

  userName = this.profileService.userName
  homeLocation = this.profileService.homeLocation
  birthYear = computed(() => (this.profileService.birthYear() && this.profileService.birthYear().toString()) || "")
  title = this.profileService.title
  loggedIn = this.profileService.loggedIn

  stepNumber = signal(1)
  wizardEnabled = computed(() =>
    this.profileIncomplete() && this.loggedIn() && this.stepNumber() !== 5
  )

  constructor() {
    effect(() => {
      const loggedIn = this.loggedIn()
      if (loggedIn) {
        untracked(() => {
          if (this.profileIncomplete() && loggedIn && this.tabIndex() === 0) {
            this.routeService.changeRoute([], { p: "my-account-profile" })
          }
        })
      }
    })
  }

  ngOnInit() {
    if (this.profileIncomplete() && this.loggedIn()) {
      // this.wizardEnabled.set(true)
    }
  }

  close() {
    // this.routeService.changeMyAccountModal(false)
    this.routeService.changeRoute([])
  }

  saveProfile() {
    this.stepNumber.update(stepNumber =>
      stepNumber < 5
        ? stepNumber + 1
        : 1
    )
    this.profileEditorService.saveProfile()
    if (this.stepNumber() === 5 && !this.profileIncomplete()) {
      // this.wizardEnabled.set(false)
    }
  }

}
