import { Injectable, signal } from "@angular/core"
import { FieldType, Image, SectionEnum, TextArea, TextAreaStyle, TextAreaStyleType } from "./fields.type"
import { LinkTargetType } from "@shared"

@Injectable({
  providedIn: "root",
})
export class FieldsService {

  serif = signal("Times New Roman, serif")
  sanSerif = signal("Arial, san-serif")

  newTextArea(section: SectionEnum, style: TextAreaStyleType): TextArea {
    return {
      rowId: "",
      field: FieldType.TEXT_AREA,
      section: section,
      style: style,
      value: "",
    }
  }

  get newImage(): Image {
    return {
      rowId: "",
      alt: "",
      field: FieldType.IMAGE,
      filePath: "",
      fileType: "",
      section: SectionEnum.IMAGE,
      svgGraphics: null,
      targetType: LinkTargetType.SELF,
      targetPath: "",
    }
  }

  textAreaStyle(style: TextAreaStyleType | undefined): TextAreaStyle | undefined {
    return this.textAreaStyles.find(textAreaStyle => textAreaStyle.style === style)
  }

  get textAreaStyles(): TextAreaStyle[] {
    return [
      {
        classes: "",
        display: undefined,
        fontFamily: undefined,
        fontSize_px: undefined,
        fontStyle: undefined,
        fontWeight: undefined,
        label: "WYSIWYG",
        lineHeight_pct: undefined,
        section: SectionEnum.WYSIWYG,
        style: TextAreaStyleType.WYSIWYG,
        textTransform: undefined,
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 140,
        fontStyle: "italic",
        fontWeight: 900,
        label: "Style 1",
        lineHeight_pct: 90,
        section: SectionEnum.TITLE,
        style: TextAreaStyleType.STYLE_1,
        textTransform: "uppercase",
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 110,
        fontStyle: "italic",
        fontWeight: 900,
        label: "Style 2",
        lineHeight_pct: 90,
        section: SectionEnum.TITLE,
        style: TextAreaStyleType.STYLE_2,
        textTransform: "uppercase",
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 80,
        fontStyle: "normal",
        fontWeight: 900,
        label: "Style 3",
        lineHeight_pct: 90,
        section: SectionEnum.TITLE,
        style: TextAreaStyleType.STYLE_3,
        textTransform: "none",
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 50,
        fontStyle: "normal",
        fontWeight: 900,
        label: "Style 4",
        lineHeight_pct: undefined,
        section: SectionEnum.TITLE,
        style: TextAreaStyleType.STYLE_4,
        textTransform: "none",
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 30,
        fontStyle: "normal",
        fontWeight: 900,
        label: "Style 5 (default)",
        lineHeight_pct: undefined,
        section: SectionEnum.TITLE,
        style: TextAreaStyleType.STYLE_5,
        textTransform: "none",
      },
      {
        classes: "",
        display: "none",
        fontFamily: undefined,
        fontSize_px: undefined,
        fontStyle: undefined,
        fontWeight: undefined,
        label: "Hidden",
        lineHeight_pct: undefined,
        section: SectionEnum.TITLE,
        style: TextAreaStyleType.HIDDEN,
        textTransform: undefined,
      },

      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 140,
        fontStyle: "italic",
        fontWeight: 900,
        label: "Style 1",
        lineHeight_pct: 90,
        section: SectionEnum.SUBTITLE,
        style: TextAreaStyleType.STYLE_1,
        textTransform: "uppercase",
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 110,
        fontStyle: "italic",
        fontWeight: 900,
        label: "Style 2",
        lineHeight_pct: 90,
        section: SectionEnum.SUBTITLE,
        style: TextAreaStyleType.STYLE_2,
        textTransform: "uppercase",
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 80,
        fontStyle: "normal",
        fontWeight: 900,
        label: "Style 3",
        lineHeight_pct: 90,
        section: SectionEnum.SUBTITLE,
        style: TextAreaStyleType.STYLE_3,
        textTransform: "none",
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 50,
        fontStyle: "normal",
        fontWeight: 900,
        label: "Style 4",
        lineHeight_pct: undefined,
        section: SectionEnum.SUBTITLE,
        style: TextAreaStyleType.STYLE_4,
        textTransform: "none",
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.sanSerif(),
        fontSize_px: 30,
        fontStyle: "normal",
        fontWeight: 900,
        label: "Style 5 (default)",
        lineHeight_pct: undefined,
        section: SectionEnum.SUBTITLE,
        style: TextAreaStyleType.STYLE_5,
        textTransform: "none",
      },
      {
        classes: "",
        display: "none",
        fontFamily: undefined,
        fontSize_px: undefined,
        fontStyle: undefined,
        fontWeight: undefined,
        label: "Hidden",
        lineHeight_pct: undefined,
        section: SectionEnum.SUBTITLE,
        style: TextAreaStyleType.HIDDEN,
        textTransform: undefined,
      },

      {
        classes: "",
        display: undefined,
        fontFamily: this.serif(),
        fontSize_px: 30,
        fontStyle: "italic",
        fontWeight: 500,
        label: "Italic",
        lineHeight_pct: undefined,
        section: SectionEnum.BODY,
        style: TextAreaStyleType.ITALIC,
        textTransform: undefined,
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.serif(),
        fontSize_px: 20,
        fontStyle: "normal",
        fontWeight: 500,
        label: "Normal (default)",
        lineHeight_pct: undefined,
        section: SectionEnum.BODY,
        style: TextAreaStyleType.NORMAL,
        textTransform: undefined,
      },
      {
        classes: "",
        display: undefined,
        fontFamily: this.serif(),
        fontSize_px: 20,
        fontStyle: "normal",
        fontWeight: 900,
        label: "Quote",
        lineHeight_pct: undefined,
        section: SectionEnum.BODY,
        style: TextAreaStyleType.QUOTE,
        textTransform: undefined,
      },
    ]
  }

}
