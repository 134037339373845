import { Component, computed, inject, signal } from "@angular/core"
import { EditorService } from "../services/editor.service"
import { PublishStatus } from "../../content/content.model"
import { SaveIconComponent } from "../../shared/save-icon.component"
import { UiRadioComponent } from "ui/radio"
import { UiInheritDirective } from "ui/inherit"

type Input = {
  selected: PublishStatus
  identifier: PublishStatus
  label: string
}

@Component({
  imports: [
    SaveIconComponent,
    UiInheritDirective,
    UiRadioComponent,
  ],
  standalone: true,
  template: `
    <div>
      Published Status:
    </div>
    @for (input of inputs(); track input.identifier) {
      <div class="row">
        <lib-ui-radio
          [name]="name"
          [identifier]="input.identifier"
          [label]="input.label"
          [selected]="input.selected"
          (changes)="change($event)"
        />
        <e2e-save-icon [modelChange]="modelChange()[input.identifier]"/>
      </div>
    }
  `,
  selector: "e2e-editor-publish-status",
})
export class EditorPublishStatusComponent {
  private editorService = inject(EditorService)

  name = "publish-status"

  selected = computed(() => this.editorService.editorContent()?.status.publishStatus)
  inputs = computed(
    () =>
      ((this.selected() &&
          this.data.map((item) => ({
            selected: this.selected(),
            identifier: item.identifier,
            label: item.label,
          }))) ||
        []) as Input[],
  )
  modelChange = signal<{ [key: string]: number }>({})

  change(publishStatus: PublishStatus) {
    this.modelChange.update((model) => ({ ...model, [publishStatus]: Date.now() }))
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        status: {
          ...content.status,
          publishStatus,
        },
      })
    }
  }

  get data(): { label: string; identifier: PublishStatus }[] {
    return [
      {
        label: "Published",
        identifier: PublishStatus.PUBLISHED,
      },
      {
        label: "Unpublished",
        identifier: PublishStatus.UNPUBLISHED,
      },
    ]
  }
}
