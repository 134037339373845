import { Component, computed, inject, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { EditorService } from "../services/editor.service"
import { SaveIconComponent } from "../../shared/save-icon.component"

@Component({
  imports: [
    FormsModule,
    SaveIconComponent,
  ],
  standalone: true,
  template: `
    <div>
      Rotation: {{ rotation() }} degrees
      <e2e-save-icon [modelChange]="modelChange()" />
    </div>
    <div style="padding: 0 15px">
      <input
        type="range"
        min="-180"
        max="180"
        class="range range-secondary w-full"
        [ngModel]="rotation()"
        (ngModelChange)="change($event)"
      />
    </div>
  `,
  selector: "e2e-editor-layout-rotation",
})
export class EditorLayoutRotationComponent {
  private editorService = inject(EditorService)

  rotation = computed(() => this.editorService.editorContent()?.settings.rotation.deg || 0)
  modelChange = signal<number | undefined>(undefined)

  change(deg: number) {
    this.modelChange.set(Date.now())
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        settings: {
          ...content.settings,
          rotation: {
            ...content.settings.rotation,
            deg,
          },
        },
      })
    }
  }
}
