import { Component, computed, effect, inject, signal } from "@angular/core"
import { UserManagerService } from "./user-manager.service"
import { UserEditComponent } from "./user-edit.component"
import { ProfileService } from "../profile/profile.service"
import { PageToolbarService } from "../page-toolbar.service"
import { UiInheritDirective } from "ui/inherit"
import { UiCollapseComponent } from "ui/collapse"

@Component({
  imports: [
    UserEditComponent,
    UiInheritDirective,
    UiCollapseComponent,
  ],
  standalone: true,
  template: `
    <div #pageToolbarPlaceholder [style.height.px]="pageToolbarHeight()"></div>
    <div
      class="column"
      style="width: fit-content; padding: 20px; gap: 0"
    >
      @if (!isUserAdmin()) {
        Access Denied
      }
      @if (users().length) {
        @for (user of users(); track user.userId; let index = $index; let first = $first; let last = $last) {
          <lib-ui-collapse
            [first]="first"
            [last]="last"
            [index]="index"
            (activated)="userIndex.set($event)"
          >

            <ng-container ngProjectAs="title-left">
              {{ user.header }}
            </ng-container>

            <ng-container ngProjectAs="content">
              @defer (when index === userIndex()) {
                <e2e-user-edit
                  [userProfile]="user.userProfile"
                  [userId]="user.userId"
                />
              }
            </ng-container>

          </lib-ui-collapse>
        }

      }
    </div>
  `,
  selector: "e2e-user-manager",
})
export class UserManagerComponent {
  private userManagerService = inject(UserManagerService)
  private profileService = inject(ProfileService)
  private pageToolbarService = inject(PageToolbarService)

  isUserAdmin = this.profileService.isUserAdmin
  userIndex = signal(-1)


  //can be input
  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight

  /**
   * TODO: convert to using profile which is available for all users
   */
  users = computed(() =>
    [...this.userManagerService.userProfiles_map().values()].map((userProfile) => ({
      ...userProfile,
      userProfile: userProfile,
      header: userProfile.userName
        ? userProfile.userName + " (" + userProfile.userEmail + ") "
        : userProfile.userEmail + " ",
    })),
  )
  /*
  users = computed(() => this.usersService.userRecords()
    .map(userRecord => ({
      ...userRecord,
      header: userRecord.displayName
        ? userRecord.displayName + " (" + userRecord.email + ") " + userRecord.uid
        : userRecord.email + " " + userRecord.uid
    }))
  )
*/

  constructor() {
    effect(() => this.pageToolbarHeight()) // required in order to trigger template refresh when pageToolbarHeight changes
  }

}
