import { Component, computed, inject, signal } from "@angular/core"
import { EditorService } from "../services/editor.service"
import { Classification, PublishStatus } from "../../content/content.model"
import { SaveIconComponent } from "../../shared/save-icon.component"
import { UiRadioComponent } from "ui/radio"
import { UiInheritDirective } from "ui/inherit"
import { RouteService } from "../../../services/route.service"
import { regionData } from "../../../regions/region.model"

type Input = {
  selected: Classification
  identifier: Classification
  label: string
}

@Component({
  imports: [
    SaveIconComponent,
    UiInheritDirective,
    UiRadioComponent,
  ],
  standalone: true,
  template: `
    <div>
      Classification:
    </div>
    @for (input of inputs(); track input.identifier) {
      <div class="row">
        <lib-ui-radio
          [name]="name"
          [identifier]="input.identifier"
          [label]="input.label"
          [selected]="input.selected"
          (changes)="change($event)"
        />
        <e2e-save-icon [modelChange]="modelChange()[input.identifier]"/>
      </div>
    }
  `,
  selector: "e2e-editor-classification",
})
export class EditorClassificationComponent {
  private editorService = inject(EditorService)
  private routeService = inject(RouteService)

  name = "classification"

  selected = computed(() => this.editorService.editorContent()?.status.classification)
  inputs = computed(
    () =>
      ((this.selected() &&
        this.data
          /**
           * This filter forces the editor to only show:
           *   National and Region options for admin content
           *   Featured and Contributed options for contributed content
           */
          // .filter(item => {
          //   switch (item.identifier) {
          //     case Classification.NATIONAL:
          //     case Classification.REGIONAL:
          //       return this.selected() === Classification.NATIONAL || this.selected() === Classification.REGIONAL
          //     case Classification.FEATURED:
          //     case Classification.CONTRIBUTED:
          //       return this.selected() === Classification.FEATURED || this.selected() === Classification.CONTRIBUTED
          //   }
          // })
          .map(item => {
              return {
                selected: this.selected(),
                identifier: item.identifier,
                label: item.label + (
                  item.identifier === Classification.REGIONAL
                    ? " (" + regionData[this.routeService.region()].label + ")"
                    : item.identifier === Classification.CONTRIBUTED
                      ? " (" + this.editorService.editorContent()?.created.userName + ")"
                      : ""
                ),
              }
            },
          )
      ) || []) as Input[],
  )
  modelChange = signal<{ [key: string]: number }>({})

  change(classification: Classification) {
    this.modelChange.update((model) => ({ ...model, [classification]: Date.now() }))
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        status: {
          ...content.status,
          classification,
          publishStatus: classification === Classification.FEATURED ? PublishStatus.UNPUBLISHED : content.status.publishStatus,
        },
      })
    }
  }

  get data(): { label: string; identifier: Classification }[] {
    return [
      {
        label: "National",
        identifier: Classification.NATIONAL,
      },
      {
        label: "Regional",
        identifier: Classification.REGIONAL,
      },
      {
        label: "Contributed",
        identifier: Classification.CONTRIBUTED,
      },
      {
        label: "Featured",
        identifier: Classification.FEATURED,
      },
    ]
  }
}
