import { Component, input, output } from "@angular/core"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    <div
      class="drawer"
      style="z-index: 10000"
    >
      <input
        type="checkbox"
        class="drawer-toggle"
        [checked]="show()"
      />
      <div class="drawer-side">
        <label class="drawer-overlay" (click)="close.emit()"></label>

        <ng-content ngProjectAs="content"></ng-content>

      </div>
    </div>
  `,
  selector: "lib-ui-drawer",
})
export class UiDrawerComponent {
  show = input(false)
  close = output()
}
