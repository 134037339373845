import { effect, inject, Injectable, signal, untracked } from "@angular/core"
import { ProfileService } from "../../profile/profile.service"
import { Content, EditStatus, ReviewStatus } from "../../content/content.model"
import { Timestamp } from "@angular/fire/firestore"
import { EditorService } from "./editor.service"
import { RouteService } from "../../../services/route.service"
import { FirestoreReadService } from "../../content/firestore-read.service"
import { UiAlertService } from "ui/alert"
import { UiToastService } from "ui/toast"

@Injectable({
  providedIn: "root",
})
export class EditorLoadService {
  private profileService = inject(ProfileService)
  private editorService = inject(EditorService)
  private routeService = inject(RouteService)
  private firestoreReadService = inject(FirestoreReadService)
  private uiAlertService = inject(UiAlertService)
  private uiToastService = inject(UiToastService)

  section = this.routeService.section
  showIsPublished_dialog = signal(false)
  showActiveReview_dialog = signal<{ content?: Content, message?: string }>({})

  constructor() {

    // const reviewer = "reviewer"
    // this.uiToastService.alertContent.set("This content is in Active-Review by " + reviewer + ". Click OK to edit. (this will cancel the review)")
    // this.uiToastService.show.set(true)


    /**
     * uses contentId from url to immediately load the editor
     * if contentEditor is currently undefined
     * waits for current save operation(s) to complete
     *
     * will cause infinite loop without these guards
     */
    effect(() => {
/*
      const editorIdFromUrl = this.routeService.editorIdFromUrl()
      const saving = this.editorService.saving()
      const preSaving = this.editorService.preSaving()
      const profile = this.profileService.profile()
      if (editorIdFromUrl && !saving && !preSaving && profile) {
        untracked(() => {
          const content = this.firestoreReadService.getContentById(editorIdFromUrl)
          if (content && !this.editorService.editorContent()) {
            this.loadEditor(content)
          }
        })
      }
*/
    })
  }

  /**
   * uses content from latest hot-observable from Firestore
   * passed in at the time the Load Editor button is clicked
   *
   * content is pushed to the ContentEdits object
   * after this click event, ContentEdits is updated by user actions
   * and diverges from any changes to the source Firestore data.
   *
   * We have a lock that cancels the editor if the userAction.reviewed.userId is not your own.
   * When a single person edits the same content from multiple browsers at the same time, they don't synchronize.
   */
  // loadEditor(content: Content) {
  //   if (this.editorService.saving() || this.editorService.preSaving())
  //     return
  //
  //   if (this.section().TIMELINE) {
  //     if (this.profileService.isEditor() || this.editorService.creatingContributedTimeline_flag()) {
  //       this.startEdit(content)
  //     }
  //   }
  //
  //   if (this.section().REDLINE_MAP) {
  //     if (this.editorService.creatingContributedLocalMap_flag()) {
  //       this.startEdit(content)
  //     }
  //   }
  //
  //   if (this.section().MANAGER) {
  //     this.startEdit(content)
  //   }
  //
  // }

  startEdit(content: Content) {
    if (this.editorService.saving() || this.editorService.preSaving())
      return

    // this.editorService.setEditorContent(content)

    const editorContent: Content = {
      ...content,
      status: {
        ...content.status,
        editStatus: EditStatus.EDITING,
      },
      userActions: {
        ...content.userActions,
        edited: [
          {
            date: Timestamp.now(),
            userId: this.profileService.userId(),
            userName: this.profileService.userName(),
            userRole: this.profileService.userRole(),
          },
          // ...content.userActions.edited.slice(-10),
        ],
      },
    }

  }

  startReview(content: Content, contentReviewStatus: ReviewStatus) {
    if (this.editorService.saving() || this.editorService.preSaving()) {
      return
    }

    const editorContent: Content = {
      ...content,
      status: {
        ...content.status,
        editStatus: EditStatus.NOT_EDITING,
        reviewStatus: contentReviewStatus,
      },
      userActions: {
        ...content.userActions,
        reviewed: [
          {
            date: Timestamp.now(),
            userId: this.profileService.userId(),
            userName: this.profileService.userName(),
            userRole: this.profileService.userRole(),
          },
          // ...content.userActions.reviewed.slice(-4),
        ],
      },
    }

  }

  hideIsPublished_dialog() {
    this.showIsPublished_dialog.set(false)
  }

}
