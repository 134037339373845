import { Component, inject } from "@angular/core"
import { Breakpoints, MediaMatcher } from "@angular/cdk/layout"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    <div class="column">
      @for (card of documentation; track card) {
        @if (card.title) {
          <div>
            <div>
              {{ card.title }}
            </div>
            @for (text of card.text; track text) {
              <div class="">
                {{ text }}
              </div>
            }
          </div>
        }
      }
    </div>
  `,
  selector: "e2e-documentation",
})
export class DocumentationComponent {
  private mediaMatcher = inject(MediaMatcher)

  documentation = [
    {
      title: "breakpoints",
      text: [
        "Web",
        Breakpoints.Web,
        "WebLandscape",
        Breakpoints.WebLandscape,
        "WebPortrait",
        Breakpoints.WebPortrait,
        "Tablet",
        Breakpoints.Tablet,
        "TabletPortrait",
        Breakpoints.TabletPortrait,
        "TabletLandscape",
        Breakpoints.TabletLandscape,
        "Handset",
        Breakpoints.Handset,
        "HandsetLandscape",
        Breakpoints.HandsetLandscape,
        "HandsetPortrait",
        Breakpoints.HandsetPortrait,
        "XSmall",
        Breakpoints.XSmall,
        "Small",
        Breakpoints.Small,
        "Medium",
        Breakpoints.Medium,
        "Large",
        Breakpoints.Large,
        "XLarge",
        Breakpoints.XLarge,
      ],
    },
    {
      title: "second",
      text: [],
    },
    {
      title: "",
      text: ["", "", ""],
    },
    {
      title: "",
      text: ["", "", ""],
    },
    {
      title: "",
      text: ["", "", ""],
    },
    {
      title: "",
      text: ["", "", ""],
    },
  ]

  constructor() {
    const mediaQueryList = this.mediaMatcher.matchMedia("(prefers-color-scheme: dark)")
    console.log('Initial state:', mediaQueryList.matches)
    mediaQueryList.addEventListener("change", () => {
      console.log(mediaQueryList.matches)
    })

  }
}
