import { Injectable, signal } from "@angular/core"

@Injectable({
  providedIn: "root"
})
export class UiToastService {
  show = signal(false)
  alertContent = signal("")

}
