import { Component, computed, inject, input, signal } from "@angular/core"
import { ImageView } from "../fields.type"
import { TimelineSvgGraphicsService } from "../../../pages/timeline/timeline.svg-graphics.service"
import { FileNamePipe } from "@shared"
import { DragClickBlockerDirective } from "../../../../directives/drag-click-blocker.directive"
import { NgOptimizedImage } from "@angular/common"

@Component({
  imports: [FileNamePipe, DragClickBlockerDirective, NgOptimizedImage],
  standalone: true,
  template: `
    @if (imageView()) {
      <img
        [ngSrc]="fileUrl()"
        [alt]="imageView().alt || 'image'"
        style="position: relative"
        [style.height.px]="height()"
        [style.width.px]="width()"
        fill
        disableOptimizedSrcset
      />
    }
  `,
  selector: "e2e-image-view",
})
export class ImageViewComponent {
  private svgGraphicsService = inject(TimelineSvgGraphicsService)

  imageView = input.required<ImageView>()
  width = computed(() => (this.imageView().contentWidth >= 0 ? this.imageView().contentWidth : undefined))
  height = signal(undefined)
  fileUrl = computed(
    () =>
      // this.domSanitizer.bypassSecurityTrustResourceUrl(
      // this.svgGraphicsService.svgGraphic(this.imageView().svgGraphics) &&
      this.svgGraphicsService.svgGraphic(this.imageView().svgGraphics) ||
      this.imageView().filePath,
    // ),
  )
}
