import { Component } from "@angular/core"
import { IntroLegacyCardsComponent } from "./intro-legacy-cards.component"
import { IntroLegacyCardComponent } from "./intro-legacy-card.component"
import { RouterLink } from "@angular/router"
import { NextSectionButtonComponent } from "@shared"
import { NgOptimizedImage } from "@angular/common"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    IntroLegacyCardsComponent,
    IntroLegacyCardComponent,
    RouterLink,
    UiInheritDirective,
    NextSectionButtonComponent,
    NgOptimizedImage,
  ],
  providers: [],
  standalone: true,
  template: `
    <div class="row">
      <div
        class="column text-black"
        style="flex: 1; padding: 20px"
      >
        <div style="line-height: .9">
          <span style="font-size: 24px; font-weight: 900;">
            THE LEGACY FOR
          </span>
          <span style="font-size: 50px; font-weight: 900;">
            TODAY
          </span>
        </div>
        <div class="row-center w-full">
          <img
            [ngSrc]="blackLivesMatterBannerImage"
            alt="black lives matter banner image"
            style="width: 50%; min-width: 300px; transform: rotate(10deg); padding: 40px; position: relative"
            fill
          >
        </div>
        <div style="font-weight: 600">
          THE STRUCTURAL SITUATION CREATED BY THESE PROGRAMS LARGELY REMAINS THE SAME. EVIDENCE OF THEIR DISPARATE
          IMPACT IS CLEAR.
        </div>
        <div>
          There is a difference between ending further harms, and undoing the harm that has been done. Policies like
          the 1968 Fair Housing Act and the Community Reinvestment Act have been largely focused on ending policies
          and practices like redlining, often without going deeper to undesign the structures of segregation and
          wealth inequality they created. But it is not true to say that we have ended racist policy and practice
          (which often illicitly continue) and therefore we can simply move on. The racial wealth gap, the geography
          of our neighborhoods, the quality of services in those areas, and much more, remain just as redlining
          designed them. So if we want a society that is fair, if we want to ‘move on,’ then we need to undesign
          redlining and policies like it. Let’s take a look at some of the inequalities that exist today.
        </div>
      </div>

      <div style="flex: 1">
        <e2e-intro-legacy-cards inherit/>
      </div>

    </div>
    <button routerLink="/redline-map" style="width: 100%">
      <lib-next-section-button/>
    </button>
  `,
  selector: "e2e-intro-legacy",
})
export class IntroLegacyComponent {
  blackLivesMatterBannerImage = "/assets/pages/intro/legacy/title-banner-image.jpg"
  // mindyBackground = "url('assets/pages/intro/mindy-background.jpg')"
}
