import { Component, computed, inject, input, output } from "@angular/core"
import { RouteService } from "../../services/route.service"
import { EditorContext, EditorService } from "./services/editor.service"
import { ProfileService } from "../profile/profile.service"
import { EditorMapComponent } from "./fields/editor-map.component"
import { ImageEditComponent } from "../shared/fields/image/image-edit.component"
import { TextAreaEditComponent } from "../shared/fields/text-area/text-area-edit.component"
import { UiInheritDirective } from "ui/inherit"
import { EditorFeedbackComponent } from "./fields/editor-feedback.component"
import { ImageViewComponent } from "../shared/fields/image/image-view.component"
import { faYinYang } from "@fortawesome/pro-solid-svg-icons"
import { UiIconComponent } from "ui/icon"
import { faTrash } from "@fortawesome/pro-light-svg-icons"
import { UiConfirmComponent } from "ui/confirm"

@Component({
  imports: [
    EditorMapComponent,
    ImageEditComponent,
    TextAreaEditComponent,
    UiInheritDirective,
    EditorFeedbackComponent,
    ImageViewComponent,
    UiIconComponent,
    UiConfirmComponent,
  ],
  standalone: true,
  template: `
    <div class="row w-full justify-between flex-nowrap">

      @if (contributorContext()) {
        @if (needsChanges()) {
          <button
            class="btn btn-sm btn-accent"
            [disabled]="saving() || preSaving()"
            (click)="resubmitForReviewAndClose.emit()"
          >
            Re-submit for review
          </button>
        }
        @if (!needsChanges()) {
          <button
            class="btn btn-sm btn-accent"
            [disabled]="saving() || preSaving()"
            (click)="saveAndClose.emit()"
          >
            Save
          </button>
        }
      }

      @if (moderationContext()) {
          <button
            class="btn btn-sm btn-accent"
            [disabled]="saving() || preSaving() || needsChanges()"
            (click)="approveAndClose.emit()"
          >
            Approve
          </button>
          <button
            class="btn btn-sm btn-accent"
            [disabled]="saving() || preSaving() || !needsChanges()"
            (click)="rejectAndClose.emit()"
          >
            Reject
          </button>
      }

      @if (!autosave() && !moderationContext() && !contributorContext()) {
        @if (!saving()) {
          <button
            class="btn btn-sm btn-accent"
            (click)="saveAndClose.emit()"
          >
            Save
          </button>
        }
        @if (saving()) {
          <button class="btn btn-sm btn-accent">
            <lib-ui-icon
              [icon]="faYinYang"
              [style.font-size.px]="15"
              [style.padding-right.px]="0"
              animation="spin"
            />
          </button>
        }
      }

      @if (adminContext()) {
        <lib-ui-confirm
          [options]="{ triggerButtonText: 'Delete', icon: faTrash, classes: ['btn-accent'] }"
          (confirmed)="deleteAndClose.emit()"
        />
      }


    </div>
  `,
  selector: "e2e-editor-footer",
})
export class EditorFooterComponent {
  private routeService = inject(RouteService)
  private editorService = inject(EditorService)
  private profileService = inject(ProfileService)

  context = input.required<EditorContext>()

  close = output()
  resubmitForReviewAndClose = output()
  approveAndClose = output()
  rejectAndClose = output()
  saveAndClose = output()
  deleteAndClose = output()

  adminContext = computed(() => this.context() === "admin")
  contributorContext = computed(() => this.context() === "contributor")
  moderationContext = computed(() => this.context() === "moderation")
  preSaving = this.editorService.preSaving
  // section = this.routeService.section
  saving = this.editorService.saving
  passedReview = this.editorService.passedReview
  // needsChanges = this.editorService.needsChanges
  isEditor = this.profileService.isEditor
  autosave = this.editorService.autosave

  needsChanges = computed(() => this.editorService.editorContent()?.status.reviewFeedbacks.length)

  protected readonly faYinYang = faYinYang
  protected readonly faTrash = faTrash
}
