import { Component, inject } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"
import { RegionService } from "../../../../regions/region.service"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    @if (brandHeader(); as header) {
        <div
          class="column justify-center items-center"
          style="flex: 1; padding-bottom: 100px"
          >
          @if (header.title) {
            <div class="text-6xl font-black">
              {{ header.title }}
            </div>
          }
          @if (header.subTitle) {
            <div class="text-md">
              {{ header.subTitle }}
            </div>
          }
          @if (header.image && header.image.src) {
            <div
              class="p-2 rounded-lg"
              [style.width]="header.style.width"
              >
              <img [ngSrc]="header.image.src" [alt]="header.image.altText" fill style="position: relative"/>
            </div>
          }
        </div>
      }
      `,
  selector: "e2e-intro-brand-header",
})
export class IntroBrandHeaderComponent {
  private regionService = inject(RegionService)

  brandHeader = this.regionService.brandHeader
}
