import { Component, ElementRef, input, signal, ViewChild } from "@angular/core"
import { from } from "rxjs"
import { tap } from "rxjs/operators"
import { IntroSubContent } from "../intro.model"
import { NgOptimizedImage } from "@angular/common"
import { prefersReducedMotionQuery } from "ui/theme"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    <div style="position: relative; max-width: 100%">
      @if (showImage()) {
        <img
          alt="image" height="117"
          [ngSrc]="introLogo().image.src"
          style="max-width: 100%; width: initial; height: initial"
          width="640"
          priority=""
          >
      }
      @if (showVideo()) {
        <div style="position: absolute; top: 0; left: 0">
          <video
            #videoElement
            [muted]="true"
            autoplay loop playsinline
            style="max-width: 100%"
            >
            <source [src]="introLogo().video.src" [type]="introLogo().video.type">
          </video>
        </div>
      }
    </div>
    `,
  selector: "e2e-intro-logo",
})
export class IntroLogoComponent {
  introLogo = input.required<IntroSubContent>()
  @ViewChild("videoElement") videoElementRef: ElementRef | undefined
  showImage = signal(true)
  showVideo = signal(!prefersReducedMotionQuery.matches)

  constructor() {
    prefersReducedMotionQuery.addEventListener("change", () => {
      this.showVideo.set(!prefersReducedMotionQuery.matches)
    })

    // this.rxEffects.register(this.timer$)
  }

/*
  timer$ = interval(1000)
    .pipe(
      tap(() => {
        // console.log(this.videoElementRef.nativeElement.currentTime)
      })
    )
*/

  get play$() {
    return from(this.videoElementRef?.nativeElement.play()).pipe(
      tap(() => {
        console.log("started play()")
      }),
    )
  }
}
