import { Component, computed, inject, input, signal } from "@angular/core"
import { Image, SectionEnum } from "./fields.type"
import { EditorContext, EditorService } from "../../editor/services/editor.service"
import { ImageEditComponent } from "./image/image-edit.component"
import { SvgEditComponent } from "./svg-edit.component"
import { VideoEditComponent } from "./video/video-edit.component"
import { Story } from "../../content/content.model"

@Component({
  imports: [
    ImageEditComponent,
    SvgEditComponent,
    VideoEditComponent,
  ],
  standalone: true,
  template: `
    <div style="display: flex; flex-wrap: wrap; gap: 10px; align-items: center">
      <div role="tablist" class="tabs tabs-lifted">
        <a
          role="tab"
          class="font-bold"
          [class]="tabIndex() === 0 ? 'tab tab-active' : 'tab'"
          style="white-space: nowrap"
          (click)="_tabIndex.set(0)"
        >
          Image
        </a>
        <div class="tab-content bg-base-100 border-base-300 rounded-box p-6">
          <e2e-image-edit [rowId]="imageRowId()"/>
        </div>

        <a
          role="tab"
          class="font-bold"
          [class]="tabIndex() === 1 ? 'tab tab-active' : 'tab'"
          style="white-space: nowrap"
          (click)="_tabIndex.set(1)"
        >
          Video
        </a>
        <div class="tab-content bg-base-100 border-base-300 rounded-box p-6">
          <e2e-video-edit [rowId]="videoRowId()"/>
        </div>

        @if (adminContext()) {
          <a
            role="tab"
            class="font-bold"
            [class]="tabIndex() === 2 ? 'tab tab-active' : 'tab'"
            style="white-space: nowrap"
            (click)="_tabIndex.set(2)"
          >
            SVG
          </a>
          <div class="tab-content bg-base-100 border-base-300 rounded-box p-6">
            @defer (when tabIndex() === 2) {
              <e2e-svg-edit [rowId]="imageRowId()"/>
            }
          </div>
        }

      </div>
    </div>
  `,
  selector: "e2e-media-editor",
})
export class MediaEditorComponent {
  private editorService = inject(EditorService)

  story = input.required<Story | undefined>()
  context = input<EditorContext | undefined>(undefined)

  adminContext = computed(() => this.context() === "admin")

  _tabIndex = signal<number | undefined>(undefined)
  tabIndex = computed<number>(() => {
    const tabIndex = this._tabIndex()
    return tabIndex !== undefined
      ? tabIndex
      : (this.editorService.editorContent()?.rows[this.imageRowId()] as Image)?.svgGraphics
        ? 2
        : 0
  })

  imageRowId = computed(() => {
    console.log(this.story())
    return this.story()?.[SectionEnum.IMAGE].rowId || ""
  })
  videoRowId = computed(() => this.story()?.[SectionEnum.VIDEO].rowId || "")

}
