import { Component, inject } from "@angular/core"
import { IntroLegacyCardComponent } from "./intro-legacy-card.component"
import { IntroLegacyCardsModalComponent } from "./intro-legacy-cards-modal.component"
import { IntroService } from "../intro.service"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    IntroLegacyCardComponent,
    IntroLegacyCardsModalComponent,
    UiInheritDirective,
  ],
  providers: [],
  standalone: true,
  styles: [],
  template: `
    <div style="display: flex; flex-direction: column; gap: 50px; padding: 70px 0; align-items: center">
      @for (data of cards(); track data.title) {
        <e2e-intro-legacy-card [cardData]="data" inherit/>
      }
    </div>
    <e2e-intro-legacy-cards-modal inherit/>
  `,
  selector: "e2e-intro-legacy-cards",
})
export class IntroLegacyCardsComponent {
  private introService = inject(IntroService)

  cards = this.introService.cards
}
