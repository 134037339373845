import { Route } from "@angular/router"
import { UserManagerComponent } from "./components/user-manager/user-manager.component"
import { IntroComponent } from "./components/pages/intro/intro.component"
import { RedlineMapComponent } from "./components/pages/redline-map.component"
import { TimelineComponent } from "./components/pages/timeline/timeline.component"
import { Section5ViewComponent } from "./components/pages/section5/section5-view.component"
import { RoutePath } from "./services/route.service"
import { DocumentationComponent } from "./components/pages/documentation.component"
import { StoriesMapComponent } from "./components/pages/stories-map.component"

export const appRoutes: Route[] = [
  { path: "", component: IntroComponent }, // section 1
  { path: RoutePath.INTRODUCTION, component: IntroComponent }, // section 1
  // { path: RoutePath.REDLINE_MAP, component: RedlineMapComponent, data: { routeHasPageToolbar: true } }, // section 2
  { path: RoutePath.REDLINE_MAP, component: RedlineMapComponent }, // section 2
  { path: RoutePath.TIMELINE, component: TimelineComponent }, // section 3
  { path: RoutePath.STORIES_MAP, component: StoriesMapComponent },
  { path: RoutePath.UNDESIGN, component: Section5ViewComponent },
  { path: RoutePath.USERS, component: UserManagerComponent },
  { path: RoutePath.DOCUMENTATION, component: DocumentationComponent },

  // { path: "content", loadChildren: () => import("./modules/content/content.module").then(m => m.ContentModule) },
  { path: "**", redirectTo: RoutePath.INTRODUCTION },
]
