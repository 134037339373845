import { Component, inject } from "@angular/core"
import { TourService } from "../../tour/tour.service"

@Component({
  imports: [],
  standalone: true,
  template: `
    <div style="font-size: 24px; font-weight: 600; padding: 10px; color: #fafafa; 
      position: absolute; left: 0; right: 0; margin: auto; 
      max-width: 400px; top: -35px; text-align: center;"
    >
      <div
        class="row"
        style="justify-content: center; background-color: black; border-radius: 50px; padding: 10px;  cursor: pointer;"
        (click)="setShowTour(true)"
      >
        <i class="pi pi-volume-up" style="font-size: 30px"></i>
        Take the virtual tour
      </div>
    </div>
  `,
  selector: "e2e-intro-virtual-tour-cta",
})
export class IntroVirtualTourCtaComponent {
  private tourService = inject(TourService)

  setShowTour(showTour: boolean) {
    this.tourService.setShowTour(showTour)
  }
}
