import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  inject,
  signal,
  viewChild,
} from "@angular/core"
import { IntroGradeComponent } from "./intro-grade.component"
import { IntroGradeModalComponent } from "./intro-grade-modal.component"
import { IntroService } from "../intro.service"
import { distinctUntilChanged, fromEvent, map, shareReplay, startWith } from "rxjs"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    IntroGradeComponent,
    IntroGradeModalComponent,
    UiInheritDirective,
  ],
  standalone: true,
  template: `
    <div #gradesElementBegin style="display: flex; flex-direction: column; align-items: center">
      <div style="padding: 20px">
        <div
          class="column bg-base-100"
          style="border-radius: 10px; padding: 20px 30px"
          data-theme="redline_dark"
        >
          <div>
            "Residential security maps" were created for 239 cities across the United States. These became the basis for
            explicitly racist disinvestment in communities of color. The example map below depicts the 1939 redlining plan
            in part of the Bronx (New York City), a community that continues to face the long-term effects of redlining.
          </div>
          <div class="center">
            CLICK BELOW to learn how neighborhoods we classified into 4 categories.
          </div>
        </div>
      </div>
      <div style="display: flex; align-items: flex-start">
        <e2e-intro-grade [grade]="grades()[0]" [visiblePercent]="visiblePercent()" style="width:300px" inherit/>
        <e2e-intro-grade [grade]="grades()[1]" [visiblePercent]="visiblePercent()" style="width:374px" inherit/>
      </div>
      <div style="display: flex; align-items: flex-end">
        <e2e-intro-grade [grade]="grades()[2]" [visiblePercent]="visiblePercent()" style="width:300px" inherit/>
        <e2e-intro-grade [grade]="grades()[3]" [visiblePercent]="visiblePercent()" style="width:374px" inherit/>
      </div>
    </div>
    <e2e-intro-grade-modal inherit/>
  `,
  selector: "e2e-intro-grades",
})
export class IntroGradesComponent implements AfterViewInit {
  private introService = inject(IntroService)

  private elementRef_begin = viewChild.required<ElementRef<HTMLElement>>("gradesElementBegin")
  private nativeElement_begin = computed(() => this.elementRef_begin().nativeElement)

  scrollPosition = signal(0)
  windowHeight = signal(0)
  elementTop = signal(0)
  elementBottom = signal(0)
  elementHeight = signal(0)

  visiblePortion = computed(() => this.scrollPosition() + this.windowHeight() - this.elementTop())
  visiblePercent = computed(() => {
    return Math.max(0, this.visiblePortion() / this.elementHeight())
  })

  grades = this.introService.grades

  ngAfterViewInit() {
    fromEvent(window, "scroll")
      .pipe(
        map((x) => window.scrollY),
        startWith(0),
        distinctUntilChanged(),
        shareReplay(1),
      )
      .subscribe((scroll) => {
        // console.log("scroll", scroll)
        // console.log("window height", window.innerHeight)
        this.scrollPosition.set(scroll)
        this.windowHeight.set(window.innerHeight)
        this.elementTop.set(this.nativeElement_begin().offsetTop + 50)
        this.elementBottom.set(this.nativeElement_begin().offsetTop + 50 + this.nativeElement_begin().offsetHeight)
        this.elementHeight.set(this.nativeElement_begin().offsetHeight)
        // console.log("begin offsetTop", this.elementTop())
        // console.log("end offsetTop", this.elementBottom())
        // console.log("height", this.elementHeight())
        // console.log(this.visiblePortion())
        // console.log(this.visiblePercent())
      })
  }
}
