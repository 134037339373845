import { Environment } from "./environment.model"

/**
 * copy/pasted from firebase project settings
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDarRt6cyUNcNqE7QMfDG1RkAXk4lEln5Y",
  authDomain: "redline-02.web.app",
  projectId: "redline-02",
  storageBucket: "redline-02.appspot.com",
  messagingSenderId: "565797954513",
  appId: "1:565797954513:web:876abe50b69da9c7a2f6f0",
  measurementId: "G-TLTXWJ68VS"
}

export const environment: Environment = {
  production: true,
  baseUrl: "https://redline-02.web.app",
  firebaseConfig,
  mapboxConfig: {
    token: "pk.eyJ1IjoiYnJhZGVuY3Jvb2tzIiwiYSI6ImNqOG91dWZzczA4OTAzMnJzZXd6anBxdnYifQ.Ulh113S3VGzwCnTmhtUtIA"
  },
  imagekit_urlEndpoint: "https://ik.imagekit.io/bdfm8ldko/redline-02",
  imagekit_publicKey: "public_yaAyPDB3oQr7+5D1+Xi1/Ea4u6o=",
}

// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
