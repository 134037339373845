import { IntroSubContent } from "../components/pages/intro/intro.model"
import { Injectable } from "@angular/core"
import { RegionConfig } from "./region.model"

@Injectable({
  providedIn: "root",
})
export class ClevelandOhRegion {

  config(): RegionConfig {
    return {
      audioTour: true,
      firewall: true,
    }
  }

  localVideo(): IntroSubContent {
    return {
      image: {
        src: "",
        style: {},
      },
      video: {},
      style: {},
      contents: [],
      subTitle: "This project is a platform for people to learn and share their stories. Watch the 2 minute video above to hear local Cleveland resident Frank Lee sharing how Redlining affected his family for 3 generations.",
      value: "",
    }
  }

  brandHeader(): IntroSubContent {
    return {
      contents: [],
      video: {},
      title: "Cleveland",
      subTitle: "presented in partnership with",
      image: {
        altText: "",
        src: "assets/pages/intro/federal-reserve-bank-of-cleveland.png",
        style: {},
      },
      style: {
        width: "360px",
      },
    }
  }

}
