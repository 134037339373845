import { Component, computed, inject, output } from "@angular/core"
import { UtilRouteListenerService } from "util/route-listener"
import { UiDialogComponent } from "ui/dialog"
import { HelpTipsTimelineComponent, } from "./help-tips-timeline.component"
import { HelpTipsRedlineMapComponent } from "./help-tips-redline-map.component"
import { HelpTipsStoriesMapComponent } from "./help-tips-stories-map.component"

@Component({
  imports: [
    UiDialogComponent,
    HelpTipsTimelineComponent,
    HelpTipsRedlineMapComponent,
    HelpTipsStoriesMapComponent,
  ],
  standalone: true,
  template: `
    @if (timeline()) {
      <lib-feature-help-tips-timeline (close)="close.emit()"/>
    }
    @if (introduction()) {
      <lib-ui-dialog (cancelAndClose)="close.emit()">
        <div ngProjectAs="title">
          Help Tips
        </div>
      </lib-ui-dialog>
    }
    @if (redlineMap()) {
      <lib-feature-help-tips-redline-map (close)="close.emit()"/>
    }
    @if (storiesMap()) {
      <lib-feature-help-tips-stories-map (close)="close.emit()"/>
    }
  `,
  selector: "lib-feature-help-tips",
})
export class HelpTipsComponent {
  private routeListenerService = inject(UtilRouteListenerService)

  page = computed(() => {
    return this.routeListenerService.pathSegments()[0]
  })

  introduction = computed(() => this.page() === "introduction")
  timeline = computed(() => this.page() === "timeline")
  redlineMap = computed(() => this.page() === "redline-map")
  storiesMap = computed(() => this.page() === "stories-map")

  close = output()

}
