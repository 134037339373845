import { computed, effect, inject, Injectable, signal, untracked } from "@angular/core"
import { ProfileService } from "../../profile/profile.service"
import { RouteService } from "../../../services/route.service"
import { Classification, Content, ContentType, LngLat, PublishStatus, ReviewStatus } from "../../content/content.model"
import { FirestoreReadService } from "../../content/firestore-read.service"
import { FirestoreWriteService } from "../../content/firestore-write.service"
import { debounceSignal } from "@ddtmm/angular-signal-generators"

export type EditorContext = "admin" | "content" | "moderation" | "contributor" | "myContent"

@Injectable({
  providedIn: "root",
})
export class EditorService {
  private profileService = inject(ProfileService)
  private routeService = inject(RouteService)
  private firestoreReadService = inject(FirestoreReadService)
  private firestoreWriteService = inject(FirestoreWriteService)

  section = this.routeService.section
  region = this.routeService.region
  userId = this.profileService.userId

  contentTypes = computed(() => this.editorContent()?.status.contentTypes || [])
  contentRowIds = computed(() => this.editorContent()?.columns[0].rows || [])

  classification = computed(() => this.editorContent()?.status.classification)
  publishStatus = computed(() => this.editorContent()?.status.publishStatus)
  published = computed(() => this.publishStatus() === PublishStatus.PUBLISHED)
  // unpublished = computed(() => this.publishStatus() === PublishStatus.UNPUBLISHED)

  reviewStatus = computed(() => this.editorContent()?.status.reviewStatus)
  passedReview = computed(() => this.reviewStatus() === ReviewStatus.PASSED)

  /**
   * use editorContent.update() to debounce and que edits,
   * saves after debounceTime without changes
   */
  private _editorContent = signal<Content | undefined>(undefined)
  editorContent = this._editorContent.asReadonly()
  private autosaveQueue_map = debounceSignal<Map<string, Content>>(new Map(), 3000)
  private autosaveQueue_isActive = computed(() => !!this.autosaveQueue_map().size)
  private manualSaveQueue_map = signal<Map<string, Content>>(new Map())

  private _creatingContributedTimeline_flag = signal<Content | false>(false)
  creatingContributedTimeline_flag = this._creatingContributedTimeline_flag.asReadonly()
  private timelineValues = signal<Content | null>(null)
  // private timelineValues = computed(() => this.creatingContributedTimelineContent_flag() && this._timelineValues() || null)

  private _creatingContributedLocalMap_flag = signal<{ storyText: string, location: LngLat } | false>(false)
  creatingContributedLocalMap_flag = this._creatingContributedLocalMap_flag.asReadonly()
  private localMapValues = signal<{ storyText: string, location: LngLat } | null>(null)
  // private localMapValues = computed(() => this._creatingContributedLocalMapContent_flag() && this._localMapValues() || null)

  showLoginProfileWizard = computed(() => this.creatingContributedLocalMap_flag() || this.creatingContributedTimeline_flag())
  showMyAccount = computed(() => !this.showLoginProfileWizard())

  private _preSaving = signal(false)
  preSaving = this._preSaving.asReadonly()
  private _saving = signal(0)
  saving = this._saving.asReadonly()

  private sourceContent = computed(() => {
    const contentId = this.editorContent()?.id
    return contentId && this.firestoreReadService.getContentById(contentId) || undefined
  })

  autosave = computed(() =>
    false
    // this.sourceContent()?.status.publishStatus === PublishStatus.UNPUBLISHED
  )

  /**
   * disables approve (button click and save operation) when any feedbacks are selected
   * aggressively disabled by checking both contentSource and editorContent
   */
  needsChanges = computed(() =>
    this.sourceContent()?.status.reviewStatus === ReviewStatus.FAILED
    || this.editorContent()?.status.reviewStatus === ReviewStatus.FAILED,
  )

  constructor() {
    /**
     * saves debounced ContentEdits changes to Firestore
     */
    effect(() => {
      const content_array = Array.from(this.autosaveQueue_map().values())
      if (content_array.length) {
        untracked(() => {
          this.saveArrayToFirestore(content_array)
          this.autosaveQueue_map.set(new Map())
        })
      }
    })

    /**
     * clear editor on url changes
     */
    effect(() => {
      /*
            const editorIdFromUrl = this.routeService.editorIdFromUrl()
            untracked(() => {
                if (!editorIdFromUrl && this.editorContent()) {
                  this.clearEditor()
                }
              },
            )
      */
    })
  }

  clearEditor() {
    this.setEditorContent(undefined)
    // this.routeService.changeFragment("")
  }

  queueEditor(content: Content) {
    this.setEditorContent(content)
    // this.queueSave(content)
  }

  private setEditorContent(content: Content | undefined) {
    this._editorContent.set(content)
  }

  /**
   * check published status from before editing started
   * save via queue if it was not published
   * don't save if it is published (user will see a save button when published)
   */
  queueSave(content: Content) {
    if (!this.autosave()) {
      this.queueForManualSave(content)
    }
    if (this.autosave()) {
      this._preSaving.set(true)
      this.queueForAutoSave(content)
    }
  }

  private queueForManualSave(content: Content) {
    this.manualSaveQueue_map.update(content_map =>
      new Map([...content_map, [content.id, content]]),
    )
  }

  private queueForAutoSave(content: Content) {
    this.autosaveQueue_map.update(content_map => {
      const new_content_map = new Map(content_map)
      new_content_map.set(content.id, content)
      return new_content_map
    })
  }

  deleteContent(content: Content) {
    this.incrementSaveCounter()
    this.firestoreWriteService.saveContent([
      {
        ...content,
        deleted: true,
      },
    ])
      .then(() => {
      })
      .catch((error: Error) => {
        console.log(error)
      })
      .finally(() => {
        this.decrementSaveCounter()
      })
  }

  incrementSaveCounter() {
    this._preSaving.set(false)
    this._saving.update(saveCount => saveCount >= 0 ? saveCount + 1 : 0)
  }

  decrementSaveCounter() {
    this._saving.update(saveCount => saveCount >= 1 ? saveCount - 1 : 0)
  }

  saveEditor(content: Content) {
    this.setEditorContent(content)
    if (this.autosaveQueue_isActive()) {
      this.queueForAutoSave(content)
    }
    if (!this.autosaveQueue_isActive()) {
      this.saveArrayToFirestore([content])
    }
  }

  loadEditor(content: Content) {
    this.setEditorContent(content)
  }

  private showLoginRequiredMessage() {
    const message = "Thank you for submitting your story for review! You need to login before you can finish submitting the story."
  }

  private showProfileRequiredMessage() {
    const message = "Thank you for submitting your story for review! You need to fill in the minimum profile before you can finish submitting the story."
  }

  private showLocalMapsMessage() {
    const message = "Thank you for submitting your story for review! You can see the stories from other users by exploring the map in the next steps."
  }

  resubmitForReview() {
    const content = this.editorContent()
    console.log(content)
    if (content) {
      this.saveEditor({
        ...content,
        status: {
          ...content.status,
          reviewStatus: ReviewStatus.PENDING,
        },
      })
    }
  }

  saveAndClose() {
    if (this.saving() || this.preSaving() || this.autosaveQueue_isActive()) return

    const content_array = Array.from(this.manualSaveQueue_map().values())
    if (content_array.length) {
      this.incrementSaveCounter()
      this.firestoreWriteService
        .saveContent(content_array)
        .then(() => {
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.decrementSaveCounter()
          // this.closeEditor()
        })
    }
  }

  approveAndClose() {
    if (this.saving() || this.preSaving() || this.autosaveQueue_isActive()) return

    const content = this.editorContent()
    if (content && this.section().MODERATION && !this.needsChanges()) {
      const previousStatus = content.status.reviewStatus
      switch (previousStatus) {
        case ReviewStatus.PASSED:
          /*
                    if (content) {
                      this.saveAndClose({
                        ...content,
                        status: {
                          ...content.status,
                          publishStatus: PublishStatus.UNPUBLISHED,
                          reviewStatus: ReviewStatus.ACTIVE,
                          contentTypes: this.addContentType(content, ContentType.CONTRIBUTED)
                        }
                      })
                    }
          */
          break
        case ReviewStatus.FAILED:
        case ReviewStatus.ACTIVE: {
          this.incrementSaveCounter()
          const saveContent = <Content>{
            ...content,
            status: {
              ...content.status,
              publishStatus: PublishStatus.PUBLISHED,
              reviewStatus: ReviewStatus.PASSED,
              contentTypes: this.removeContentType(content, ContentType.CONTRIBUTED),
            },
          }
          this.setEditorContent(saveContent)
          this.firestoreWriteService.saveContent([saveContent])
            .then(() => {
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.decrementSaveCounter()
              // this.closeEditor()
            })
        }
          break
      }
    }
  }

  reject() {
    const content = this.editorContent()
    console.log(content)
    if (content && this.section().MODERATION) {
      const publishStatus = PublishStatus.UNPUBLISHED
      const contentTypes = this.addContentType(content, ContentType.CONTRIBUTED)
      switch (content.status.reviewStatus) {
        case ReviewStatus.FAILED:
          this.saveEditor(content && {
            ...content,
            status: {
              ...content.status,
              publishStatus,
              reviewStatus: ReviewStatus.ACTIVE,
              contentTypes,
            },
          })
          break
        case ReviewStatus.PASSED:
        case ReviewStatus.ACTIVE:
          this.saveEditor(content && {
            ...content,
            status: {
              ...content.status,
              publishStatus,
              reviewStatus: ReviewStatus.FAILED,
              contentTypes,
            },
          })
          break
      }
    }
  }

  addContentType(content: Content, value: ContentType): ContentType[] {
    const types = content.status.contentTypes
    const valueIndex = types.indexOf(value)
    const needsValue = valueIndex === -1

    if (needsValue) {
      return [...types, value]
    }
    return types
  }

  removeContentType(content: Content, value: ContentType): ContentType[] {
    const types = content.status.contentTypes
    const valueIndex = types.indexOf(value)
    const hasValue = valueIndex !== -1

    if (hasValue) {
      return [...types.slice(0, valueIndex), ...types.slice(valueIndex + 1)]
    }
    return types
  }

  createTimelineContent(fromContent?: Content) {
    // if (this.saving() || this.preSaving() || this.autosaveQueue_isActive())
    //   return

    // this.unsetCreatingContributedTimeline_flag()
    // if (classification === Classification.CONTRIBUTED && fromContent) {
    //   this.setCreatingContributedTimeline_flag(fromContent)
    // }

    const user = {
      id: this.profileService.userId(),
      name: this.profileService.userName(),
      role: this.profileService.userRole(),
      region: this.profileService.region(),
    }
/*
    if (!(user.id && user.name && user.role)) {
      this.routeService.changeRoute([], { p: "my-account-login", s: "" })
      return
    }

    if (this.profileService.profileStatus().progress === ProfileProgress.INCOMPLETE) {
      this.routeService.changeRoute([], { p: "my-account-profile", s: "" })
      return
    }
*/

    const newContent = this.firestoreReadService
      .newContent(
        user,
        [ContentType.TIMELINE, ContentType.CONTRIBUTED],
        Classification.CONTRIBUTED
      )
    const content: Content = {
      ...newContent,
      settings: {
        ...newContent.settings,
        links: fromContent?.id && [fromContent.id] || [],
        position: {
          layer: 5,
          left: { px: 777 },
          top: { px: 555 },
        },
      },
    }

    return content

    // this.saveNewTimelineContent(content)
  }

  private saveNewTimelineContent(content: Content) {
    this.incrementSaveCounter()
    this.firestoreWriteService.saveContent([content])
      .then(() => {
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        // this.routeService.changeRoute(["timeline"], "edit/" + content.id)
        // console.log("saveNewTimelineContent")
        // this.unsetCreatingContributedTimeline_flag()

        this.decrementSaveCounter()
      })
  }

  private saveArrayToFirestore(content_array: Content[]) {
    // if (this.saving() || this.preSaving() || this.autosaveQueue_isActive()) return
    this.incrementSaveCounter()
    this.firestoreWriteService.saveContent(content_array)
      .then(() => {
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.decrementSaveCounter()
      })
  }

  saveContent(content: Content) {
    return this.firestoreWriteService.saveContent([content])
  }

}
