import { Component, inject } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"
import { MapService } from "../map/map.service"
import { UiDialogComponent } from "ui/dialog"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    NgOptimizedImage,
    UiInheritDirective,
    UiDialogComponent,
  ],
  standalone: true,
  template: `
    @if (showFhaManual()) {
      <lib-ui-dialog (cancelAndClose)="closeModal()">
        <div ngProjectAs="title">
          FHA Manual
        </div>

        <div ngProjectAs="content">
          <div class="column" style="padding: 0 200px">
            <img
              [ngSrc]="underwritingImage"
              width="2550"
              height="4200"
              alt="image"
              priority=""
            >
          </div>
        </div>
      </lib-ui-dialog>
    }
  `,
  selector: "e2e-underwriting-manual-dialog",
})
export class UnderwritingManualDialogComponent {
  private localMapsService = inject(MapService)

  showFhaManual = this.localMapsService.underwritingManual

  underwritingImage = "/assets/images/underwriting-image.png"
  // screenshot3 = "/assets/pages/local-maps/ScreenShot3.png"
  // screenshot4 = "/assets/pages/local-maps/ScreenShot4.png"
  // screenshot5 = "/assets/pages/local-maps/ScreenShot5.png"

  closeModal() {
    this.localMapsService.closeModal()
  }

}
