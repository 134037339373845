import { Component, output } from "@angular/core"
import { UiDialogComponent } from "ui/dialog"

@Component({
  imports: [
    UiDialogComponent,
  ],
  standalone: true,
  template: `
    <lib-ui-dialog [alignTitle]="'left'" (cancelAndClose)="close.emit()">
      <div ngProjectAs="title">
        Welcome to the Interactive Timeline
      </div>

      <div ngProjectAs="content">

        <div class="column">
          <div>
            Drag the timeline to navigate. Use the zoom buttons at the bottom right to zoom in or zoom out.  If you are using a keyboard, you can also use the ←↑→↓ arrow keys to explore.
          </div>
          <div>
            The top of this timeline shows the history of structural racism and oppression. The bottom shows the history of community resilience and response.
          </div>
          <div>
            If you would like to learn more about a piece of this history, add your own story, or see related stories uploaded by the community, just click on that piece of history.  The red tags indicate if someone else has already shared a story about this.
          </div>
          <div>
            To see this message again, just click on the information icon at the top right of the screen.
          </div>
          <div class="row">
            <button
              class=" btn btn-primary"
              (click)="close.emit()"
            >
              I'm ready to explore the timeline
            </button>
          </div>
        </div>
      </div>
    </lib-ui-dialog>
  `,
  selector: "lib-feature-help-tips-timeline",
})
export class HelpTipsTimelineComponent {

  close = output()

}
