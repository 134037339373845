import { Component, computed, inject, input, signal } from "@angular/core"
import { SectionEnum, TextArea, TextAreaStyleType } from "../fields.type"
import { FieldsService } from "../fields.service"
import { EditorContext, EditorService } from "../../../editor/services/editor.service"
import { SaveIconComponent } from "../../save-icon.component"
import { ContentType } from "../../../content/content.model"
import { ProfileService } from "../../../profile/profile.service"
import { RouteService } from "../../../../services/route.service"
import { UiInheritDirective } from "ui/inherit"
import { UiTextareaComponent } from "ui/textarea"
import { UiRadioComponent } from "ui/radio"

type Options = {
  theme: string
  modules: boolean
  placeholder: string
}

type Input = {
  selected: TextAreaStyleType
  label: string
  identifier: TextAreaStyleType
}

@Component({
  imports: [
    SaveIconComponent,
    UiInheritDirective,
    UiTextareaComponent,
    UiRadioComponent,
  ],
  standalone: true,
  template: `
    <div class="column">
      @if (titleSection()) {
        <div>
          {{ titleSection() }}
          <e2e-save-icon [modelChange]="modelChange()"/>
        </div>
        <lib-ui-textarea
          [rows]="1"
          placeholder="enter text here"
          [value]="textAreaRowValue()"
          (changes)="changeTextValue($event)"
        />
      }
      @if (subTitleSection() || bodySection() || noteSection()) {
        <div>
          {{ subTitleSection() || bodySection() || noteSection() }}
          <e2e-save-icon [modelChange]="modelChange()"/>
        </div>
        <lib-ui-textarea
          [rows]="subTitleSection() ? 1 : 6"
          placeholder="enter text here"
          [value]="textAreaRowValue()"
          (changes)="changeTextValue($event)"
        />
      }
      @if (showStyleOptions()) {
        <div>
          {{ titleSection() || subTitleSection() || bodySection() || noteSection() }}
          Styles:
        </div>
        <div class="column">
          @for (input of inputs(); track input.identifier) {
            <div class="row">
              <lib-ui-radio
                [name]="radioName()"
                [label]="input.label"
                [selected]="input.selected"
                [identifier]="input.identifier"
                (changes)="changeRadioStyle($event)"
              />
              <e2e-save-icon [modelChange]="modelChangeRadio()[input.identifier]"/>
            </div>
          }
        </div>
      }
    </div>
  `,
  selector: "e2e-text-area-edit",
})
export class TextAreaEditComponent {
  private fieldService = inject(FieldsService)
  private editorService = inject(EditorService)
  private profileService = inject(ProfileService)
  private routeService = inject(RouteService)

  rowId = input.required<string>()
  context = input<EditorContext | undefined>(undefined)

  showStyleOptions = computed(
    () => this.context() === "admin" && this.routeService.section().TIMELINE && this.inputs().length,
  )

  radioName = computed(() => "text-area-style-" + this.rowId())
  timelineContent = computed(() =>
    this.editorService.editorContent()?.status.contentTypes?.includes(ContentType.TIMELINE),
  )
  textAreaRow = computed<TextArea | undefined>(
    () => this.editorService.editorContent()?.rows[this.rowId()] as TextArea | undefined,
  )
  textAreaRowValue = computed(() => this.textAreaRow()?.value || "")
  textAreaRowStyle = computed(() => this.textAreaRow()?.style)
  titleSection = computed(() => this.textAreaRow()?.section === SectionEnum.TITLE && "Title")
  subTitleSection = computed(() => this.textAreaRow()?.section === SectionEnum.SUBTITLE && "Subtitle")
  bodySection = computed(() => this.textAreaRow()?.section === SectionEnum.BODY && "Body")
  noteSection = computed(() => this.textAreaRow()?.section === SectionEnum.NOTE && "Curator's Notes")

  modelChange = signal<string | undefined>(undefined)
  modelChangeRadio = signal<{ [key: string]: number }>({})

  options: Options | undefined
  textAreaSection = computed(() => this.textAreaRow()?.section)
  textAreaStylesArray = computed(() =>
    this.fieldService.textAreaStyles.filter((textAreaStyle) => textAreaStyle.section === this.textAreaSection()),
  )

  inputs = computed(
    () =>
      ((this.textAreaRow() &&
        this.textAreaStylesArray().map((item) => ({
          selected: this.textAreaRowStyle(),
          identifier: item.style,
          label: item.label,
        }))) ||
        []) as Input[],
  )

  changeRadioStyle(style: TextAreaStyleType) {
    this.modelChangeRadio.update((model) => ({ ...model, [style]: Date.now() }))
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        rows: {
          ...content.rows,
          [this.rowId()]: {
            ...content.rows[this.rowId()],
            style,
          },
        },
      })
    }
  }

  changeTextValue(value: string) {
    this.modelChange.set(value)
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        rows: {
          ...content.rows,
          [this.rowId()]: {
            ...content.rows[this.rowId()],
            value,
          },
        },
      })
    }
  }
}
