import { Component, computed, inject, signal } from "@angular/core"
import { EditorService } from "../services/editor.service"
import { ReviewFeedback, reviewFeedbackData, ReviewStatus } from "../../content/content.model"
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons"
import { SaveIconComponent } from "../../shared/save-icon.component"
import { wrapInQuotes } from "util/string-transform"
import { UiCheckboxComponent } from "ui/checkbox"
import { UiIconComponent } from "ui/icon"
import { UiInheritDirective } from "ui/inherit"

type Input = {
  selected: boolean
  identifier: ReviewFeedback
  label: string
  description: string
}

@Component({
  imports: [
    SaveIconComponent,
    UiInheritDirective,
    UiIconComponent,
    UiCheckboxComponent,
  ],
  standalone: true,
  template: `
    @for (input of inputs(); track input.identifier) {
      <div class="column gap-0">
        <div class="row">
          <lib-ui-checkbox
            [name]="name"
            [selected]="input.selected"
            [label]="input.label"
            [identifier]="input.identifier"
            (changes)="changes($event, input.identifier)"
            inherit
          />
          <e2e-save-icon [modelChange]="modelChange()[input.identifier]"/>
        </div>
        <div class="text-sm pl-9">
          {{ input.description }}
        </div>
      </div>
    }
  `,
  selector: "e2e-editor-feedback",
})
export class EditorFeedbackComponent {
  private editorService = inject(EditorService)

  name = "feedback"

  reviewFeedbacks = computed(() => this.editorService.editorContent()?.status.reviewFeedbacks || [])
  inputs = computed(
    () =>
      reviewFeedbackData.map((item) => ({
        selected: this.reviewFeedbacks().includes(item.identifier),
        identifier: item.identifier,
        label: item.label,
        description: wrapInQuotes(item.description),
      })) as Input[],
  )
  modelChange = signal<{ [key: string]: any }>({})

  changes(event: boolean, identifier: ReviewFeedback) {
    this.modelChange.update((model) => ({ ...model, [identifier]: event }))
    const index = this.reviewFeedbacks().findIndex((item) => item === identifier)
    const reviewFeedbacks =
      (event && [...this.reviewFeedbacks(), identifier]) ||
      (index !== -1 && [...this.reviewFeedbacks().slice(0, index), ...this.reviewFeedbacks().slice(index + 1)]) ||
      []
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        status: {
          ...content.status,
          reviewFeedbacks,
          reviewStatus: reviewFeedbacks.length ? ReviewStatus.FAILED : ReviewStatus.ACTIVE,
        },
      })
    }
  }

  protected readonly faCircleQuestion = faCircleQuestion
}
