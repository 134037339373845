import { Component, computed, inject, signal, viewChild } from "@angular/core"
import { TourService } from "./tour.service"
import { TourAudioPlayerComponent } from "./tour-audio-player.component"
import { ProfileService } from "../profile/profile.service"
import { AudioStatus } from "./tour.model"
import { faChevronLeft, faChevronRight, faClose } from "@fortawesome/pro-solid-svg-icons"
import { TourEditItemsComponent } from "./tour-edit-items.component"
import { UiInheritDirective } from "ui/inherit"
import { UiDialogComponent } from "ui/dialog"
import { PageToolbarService } from "../page-toolbar.service"
import { UiIconComponent } from "ui/icon"

@Component({
  imports: [
    TourAudioPlayerComponent,
    TourEditItemsComponent,
    UiInheritDirective,
    UiIconComponent,
    UiDialogComponent,
  ],
  standalone: true,
  template: `
    @defer (when isAdmin()) {
      <div (click)="$event.stopPropagation()">
        @if (showTourEditor()) {
          <e2e-tour-edit-items
            [activeIndex]="[currentItemIndex()]"
            (closeTourEditor)="showTourEditor.set(false)"
            inherit
          />
        }
      </div>
    }

    @if (showTour()) {
      <lib-ui-dialog (cancelAndClose)="setShowTour(false)" [scrollableBackground]="true" position="top"
        [offset]="pageToolbarHeight() + 10">
        <div
          ngProjectAs="content"
          class="row"
          style="align-items: flex-start; padding: 10px"
        >
          <!--
                    <button
                      class="btn btn-sm blue"
                      (click)="setShowTour(false)"
                    >
                      <lib-ui-icon [icon]="faClose"/>
                    </button>
          -->

          <div
            class="column"
            style="flex: 1; align-items: center"
          >
            <div
              class="row"
              style="align-items: stretch; gap: 0; flex-wrap: nowrap"
            >
              <div
                class="row"
                style="justify-content: center; cursor: pointer; padding: 0 20px"
                (click)="changeToPreviousItem()"
              >
                <lib-ui-icon
                  [icon]="faChevronLeft"
                  [style.opacity]="currentItemIndex() > 0 ? 1 : 0.3"
                />
              </div>

              <e2e-tour-audio-player
                #tourAudioPlayer
                [interval]="0"
                [item]="currentItem()"
                (changeToNextItem)="changeToNextItem()"
                (changeToNextSegment)="changeToNextSegment()"
                (changeAudioStatus)="changeAudioStatus($event)"
                inherit
              />

              <div
                class="row"
                style="justify-content: center; cursor: pointer; padding: 0 20px"
                (click)="changeToNextItem()"
              >
                <lib-ui-icon
                  [icon]="faChevronRight"
                  [style.opacity]="currentItemIndex() < tourItems().length - 1 ? 1 : 0.3"
                />
              </div>

            </div>
            <!--
                        <div (click)="clickTitle()">
                          {{ currentItem()?.sectionTitle }}
                          {{ currentItem()?.title }}
                        </div>
            -->

          </div>
        </div>

      </lib-ui-dialog>
    }
  `,
  selector: "e2e-tour",
})
export class TourComponent {
  private tourService = inject(TourService)
  private profileService = inject(ProfileService)
  private pageToolbarService = inject(PageToolbarService)
  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight

  private tourAudioPlayer = viewChild<TourAudioPlayerComponent>("tourAudioPlayer")

  showTour = this.tourService.showTour
  isAdmin = this.profileService.isAdmin

  tourItems = this.tourService.tourItems
  tourItems_map = this.tourService.tourItems_map
  audioStatus = signal<AudioStatus>(AudioStatus.pause)
  audioStatusMessage = computed(() => (this.audioStatus() === "playing" ? "Pause the Tour" : "Resume the Tour"))
  // startingAudioFilePath = signal(0)

  currentItemIndex = signal(0)
  previousItemIndex = computed(() => (this.currentItemIndex() ? this.currentItemIndex() - 1 : 0))
  nextItemIndex = computed(() =>
    this.currentItemIndex() < this.tourItems().length - 1 ? this.currentItemIndex() + 1 : this.tourItems().length,
  )
  currentItem = computed(() => this.tourItems_map().get(this.currentItemIndex()))
  previousItem = computed(() => this.tourItems_map().get(this.previousItemIndex()))
  nextItem = computed(() => this.tourItems_map().get(this.nextItemIndex()))

  interval = signal(0)
  // trackDuration = signal(0)

  showTourEditor = signal(false)

  changeToPreviousItem() {
    this.currentItemIndex.set(this.previousItemIndex())
  }

  changeToNextItem() {
    this.currentItemIndex.set(this.nextItemIndex())
  }

  changeToNextSegment() {
  }

  changeAudioStatus(audioStatus: AudioStatus) {
    this.audioStatus.set(audioStatus)
  }

  exitTour(event: Event) {
    event.stopPropagation()
    this.setShowTour(false)
  }

  clickTitle() {
    if (this.isAdmin()) {
      this.tourAudioPlayer()?.pause()
      this.showTourEditor.set(true)
      this.tourService.setShowTour(false)
    }
  }

  setShowTour(showTour: boolean) {
    this.tourService.setShowTour(showTour)
    // if (showTour) {
    //   this.load(this.currentItemIndex())
    // }
  }

  protected readonly faChevronRight = faChevronRight
  protected readonly faChevronLeft = faChevronLeft
  protected readonly faClose = faClose
}
