import { computed, inject, Injectable } from "@angular/core"
import { RouteService } from "../services/route.service"
import { ClevelandOhRegion } from "./cleveland-oh.region"
import { BaltimoreMdRegion } from "./baltimore-md.region"
import { RegionEnum } from "./region.model"
import { DetroitMiRegion } from "./detroit-mi.region"
import { BostonMaRegion } from "./boston-ma.region"

@Injectable({
  providedIn: "root",
})
export class RegionService {
  private routeService = inject(RouteService)
  private clevelandOhRegion = inject(ClevelandOhRegion)
  private baltimoreMdRegion = inject(BaltimoreMdRegion)
  private detroitMiRegion = inject(DetroitMiRegion)
  private bostonMaRegion = inject(BostonMaRegion)

  config = computed(() => {
    switch (this.routeService.region()) {
      case RegionEnum.BALTIMORE_MD:
        return this.baltimoreMdRegion.config()
      case RegionEnum.CLEVELAND_OH:
        return this.clevelandOhRegion.config()
      case RegionEnum.DETROIT_MI:
        return this.detroitMiRegion.config()
      case RegionEnum.BOSTON_MA:
        return this.bostonMaRegion.config()
      default:
        return undefined
    }
  })

  localVideo = computed(() => {
    switch (this.routeService.region()) {
      case RegionEnum.BALTIMORE_MD:
        return this.baltimoreMdRegion.localVideo()
      case RegionEnum.CLEVELAND_OH:
        return this.clevelandOhRegion.localVideo()
      case RegionEnum.DETROIT_MI:
        return this.detroitMiRegion.localVideo()
      case RegionEnum.BOSTON_MA:
        return this.bostonMaRegion.localVideo()
      default:
        return undefined
    }
  })

  brandHeader = computed(() => {
    switch (this.routeService.region()) {
      case RegionEnum.BALTIMORE_MD:
        return this.baltimoreMdRegion.brandHeader()
      case RegionEnum.CLEVELAND_OH:
        return this.clevelandOhRegion.brandHeader()
      case RegionEnum.DETROIT_MI:
        return this.detroitMiRegion.brandHeader()
      case RegionEnum.BOSTON_MA:
        return this.bostonMaRegion.brandHeader()
      default:
        return undefined
    }
  })

}
