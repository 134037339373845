import { inject, Injectable, Injector } from "@angular/core"
import { Analytics, AnalyticsCallOptions, isSupported, logEvent, setUserId } from "@angular/fire/analytics"

// import { AnalyticsCallOptions, isSupported, logEvent } from "@angular/fire/analytics"

export enum EventName {
  // custom EventNames
  CUSTOM = "CUSTOM",
  // copied from EventNameString type definition
  ADD_PAYMENT_INFO = "add_payment_info",
  ADD_SHIPPING_INFO = "add_shipping_info",
  ADD_TO_CART = "add_to_cart",
  ADD_TO_WISHLIST = "add_to_wishlist",
  BEGIN_CHECKOUT = "begin_checkout",
  CHECKOUT_PROGRESS = "checkout_progress",
  EXCEPTION = "exception",
  GENERATE_LEAD = "generate_lead",
  LOGIN = "login",
  PAGE_VIEW = "page_view",
  PURCHASE = "purchase",
  REFUND = "refund",
  REMOVE_FROM_CART = "remove_from_cart",
  SCREEN_VIEW = "screen_view",
  SEARCH = "search",
  SELECT_CONTENT = "select_content",
  SELECT_ITEM = "select_item",
  SELECT_PROMOTION = "select_promotion",
  SET_CHECKOUT_OPTION = "set_checkout_option",
  SHARE = "share",
  SIGN_UP = "sign_up",
  TIMING_COMPLETE = "timing_complete",
  VIEW_CART = "view_cart",
  VIEW_ITEM = "view_item",
  VIEW_ITEM_LIST = "view_item_list",
  VIEW_PROMOTION = "view_promotion",
  VIEW_SEARCH_RESULTS = "view_search_results"
}

export type CustomEvent = {
  eventName: EventName | string
  eventParams: {
    [key: string]: any
  }
  options?: AnalyticsCallOptions
}

export interface ExceptionEvent extends CustomEvent {
  eventName: EventName.EXCEPTION
  eventParams: {
    description?: string
    fatal?: boolean
    [key: string]: any
  }
  options?: AnalyticsCallOptions
}

export interface LoginEvent extends CustomEvent {
  eventName: EventName.LOGIN
  eventParams: {
    provider: string
    [key: string]: any
  }
  options?: AnalyticsCallOptions
}

export interface PageViewEvent extends CustomEvent {
  eventName: EventName.PAGE_VIEW
  eventParams: {
    page_title?: string
    page_location?: string
    page_path?: string
    [key: string]: any
  }
  options?: AnalyticsCallOptions
}

export interface ScreenViewEvent extends CustomEvent {
  eventName: EventName.SCREEN_VIEW
  eventParams: {
    firebase_screen: string
    firebase_screen_class: string
    [key: string]: any
  }
  options?: AnalyticsCallOptions
}

export interface ShareEvent extends CustomEvent {
  eventName: EventName.SHARE
  eventParams: {
    method?: string
    content_type?: string
    item_id?: string
    [key: string]: any
  }
  options?: AnalyticsCallOptions
}

export interface SignUpEvent extends CustomEvent {
  eventName: EventName.SIGN_UP
  eventParams: {
    method?: string
    [key: string]: any
  }
  options?: AnalyticsCallOptions
}

@Injectable({
  providedIn: "root"
})
export class UtilAnalyticsService {
  private injector = inject(Injector)
  private analytics: Analytics | undefined

  constructor() {
    isSupported()
      .then((isSupported: boolean) => {
        if (isSupported) {
          this.analytics = this.injector.get(Analytics)
        }
      })
  }

  setUserId(userId: string) {
    if (this.analytics) {
      setUserId(this.analytics, "user_id")
    }
  }

  sendEvent(event: CustomEvent) {
    if (this.analytics) {
      // console.log(event.eventParams?.["firebase_screen"])
      logEvent(this.analytics, event.eventName, event.eventParams, event.options)

      /*
            switch (event.eventName) {
              case EventName.EXCEPTION:
                event = this.formatStack(event)
                this.errorMessageService.notify({
                  severity: "error",
                  summary: "We've detected an internal error",
                  detail: "and we are already working on it",
                  sticky: false,
                  life: 8000,
                })
                break
            }
      */

    }
  }

  sendExceptionEvent(event: ExceptionEvent) {
    this.sendEvent(event)
  }

  sendScreenViewEvent(screenName: string, className: string) {
    const event: ScreenViewEvent = {
      eventName: EventName.SCREEN_VIEW,
      eventParams: {
        firebase_screen: screenName,
        firebase_screen_class: className,
      }
    }
    this.sendEvent(event)
  }

  sendLoginEvent(provider: string) {
    const event: LoginEvent = {
      eventName: EventName.LOGIN,
      eventParams: {
        provider,
      }
    }
    this.sendEvent(event)
  }


  /**
   * For more info on preset log event types, see node_modules/firebase/index.d.ts
   * line 4571: declare namespace firebase.analytics
   *
   * also
   * https://developers.google.com/gtagjs/reference/ga4-events
   * and
   * https://developers.google.com/analytics/devguides/collection/ga4/exceptions
   */

  formatStack(event: CustomEvent) {
    if (event.eventParams?.["stack"]) {
      const stack = event.eventParams["stack"]
      const stackLines = stack.split("at ")
      for (const [index, stackLine] of stackLines.entries()) {
        if (index < 20) {
          event.eventParams["stack-" + String(index)] = stackLine
        }
      }
      event.eventParams["stack"] = String(stack)
    }
    return event
  }
}
