import { effect, inject, Injectable, signal, untracked } from "@angular/core"
import { RouteService } from "../services/route.service"

@Injectable({
  providedIn: "root"
})
export class PageMenuService {
  private routeService = inject(RouteService)

  private _showMenu = signal(false)
  showMenu = this._showMenu.asReadonly()

  constructor() {
    effect(() => {
      this.routeService.url()
      untracked(() => this.unsetShowMenu())
    })
  }


  toggleMenu() {
    this._showMenu.update(value => !value)
  }

  setShowMenu(value: boolean) {
    this._showMenu.set(value)
  }

  unsetShowMenu() {
    this._showMenu.set(false)
  }

}
