import { Component, input, output } from "@angular/core"
import { FormsModule } from "@angular/forms"

@Component({
  imports: [
    FormsModule,
  ],
  standalone: true,
  template: `
    <input
      class="radio radio-secondary"
      type="radio"
      [name]="name()"
      [id]="identifier()"
      [value]="identifier()"
      [ngModel]="selected()"
      (ngModelChange)="changes.emit($event)"
    />
    <label [for]="identifier()">
      {{ label() }}
    </label>
  `,
  selector: 'lib-ui-radio',
})
export class UiRadioComponent {
  name = input.required<string>()
  label = input<string>()
  identifier = input<any>()
  selected = input<any>()
  changes = output<any>()
}
