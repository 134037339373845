import { Component, input, output } from "@angular/core"
import { FormsModule } from "@angular/forms"

@Component({
  imports: [
    FormsModule,
  ],
  standalone: true,
  template: `
    <textarea
      class="textarea textarea-secondary"
      [class]="class()"
      [style]="style()"
      [rows]="rows()"
      [placeholder]="placeholder()"
      [ngModel]="value()"
      (ngModelChange)="changes.emit($event)"
    ></textarea>
  `,
  selector: 'lib-ui-textarea',
})
export class UiTextareaComponent {
  placeholder = input<string>("")
  value = input<string>("")
  rows = input<number>(4)
  style = input<string>("")
  class = input<string>("")

  changes = output<string>()
}
