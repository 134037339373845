import { Directive, HostListener, inject, OnDestroy, Renderer2 } from "@angular/core"
import { Subscription } from "rxjs"
import { TimelineService } from "../components/pages/timeline/timeline.service"

@Directive({
  selector: "div, button, a, img",
  standalone: true,
})
export class DragClickBlockerDirective implements OnDestroy {
  private timelineService = inject(TimelineService)
  private renderer = inject(Renderer2)

  subscription: Subscription | undefined

  @HostListener("click", ["$event"])
  clickEvent(event: Event): void {
    if (this.timelineService.dragging()) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  @HostListener("dragstart", ["$event"])
  onDragStart(event: DragEvent): void {
    if (event.target instanceof HTMLImageElement) {
      event.preventDefault()
    }
  }

  @HostListener("dragover", ["$event"])
  onDragOver(event: DragEvent): void {
    if (this.timelineService.dragging()) {
      event.preventDefault()
    }
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    if (this.timelineService.dragging()) {
      // this.renderer.setStyle(document.body, 'user-select', 'none');
    }
  }

  @HostListener('mouseup')
  onMouseUp(): void {
    // this.renderer.removeStyle(document.body, 'user-select');
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }
}
