export type IntroLegacyCardData = {
  copyArray: {
    type: "title" | "paragraph" | "video/webm" | "brandHeader" | "image"
    value: string
  }[]
  image: {
    src: string
    altText: string
  }
  style: {
    marginLeft: number
  }
  title: string,
  type: {
    current: LegacyForTodayCard
    next: LegacyForTodayCard
    previous: LegacyForTodayCard
  }
}

export type IntroGradeData = {
  copyArray: string[]
  grade: Grade
  imageDescription: {
    src: string
    altText: string
  }
  imageMap: {
    map_src: string
    zone_src: string
    altText: string
    width: number
    height: number
    style: {
      width: string
    }
  }
  imagePicture: {
    src: string
    altText: string
    style: {
      transform: string
    }
  }
  style: {
    transform: string
  }
  title: string
}

export type IntroSubContent = {
  content?: IntroSubContent
  contents: IntroSubContent[]
  image: {
    src: string
    altText?: string
    width?: number
    height?: number
    style: {
      width?: string
    }
  }
  modal?: IntroSubContent
  style: {
    backgroundImage?: string
    marginLeft?: number
    width?: string
    transform?: string
  }
  subTitle?: string,
  title?: string,
  type?: "title" | "paragraph" | "video/webm" | "brandHeader" | "image"
  value?: string
  video: {
    src?: string
    type?: "video/webm"
  }
}

export type IntroContent = {
  brandHeader?: IntroSubContent
  logos: IntroSubContent[]
  localVideo?: IntroSubContent
  // legacyForToday: IntroSubContent
}

export enum LegacyForTodayCard {
  BANKING = "BANKING",
  HEALTH = "HEALTH",
  POLICING = "POLICING",
  WEALTH = "WEALTH"
}

export enum Grade {
  GRADE_A = "GRADE_A",
  GRADE_B = "GRADE_B",
  GRADE_C = "GRADE_C",
  GRADE_D = "GRADE_D",
}
