import { Field, Image, SectionEnum, TextArea, Video } from "../shared/fields/fields.type"
import { RegionEnum } from "../../regions/region.model"
import { Timestamp } from "@angular/fire/firestore"
import { z } from "zod"
import { Role } from "../profile/profile.service"

// Define Zod schemas for each type
const RegionZod = z.nativeEnum(RegionEnum)
const RoleZod = z.nativeEnum(Role)


const ColumnZod = z.object({
  rows: z.array(z.string()),
})
export type Column = z.infer<typeof ColumnZod>

const PositionZod = z.object({
  layer: z.number(),
  left: z.object({
    px: z.number(),
  }),
  top: z.object({
    px: z.number(),
  }),
})
export type Position = z.infer<typeof PositionZod>

const LngLatZod = z.object({
  lat: z.number(),
  lng: z.number(),
})
export type LngLat = {
  lat: number
  lng: number
}

const ContentSettingsZod = z.object({
  links: z.array(z.string()),
  location: LngLatZod,
  position: PositionZod,
  rotation: z.object({
    deg: z.number(),
  }),
  title: z.string(),
  width: z.object({
    px: z.number(),
  }),
})
export type ContentSettings = z.infer<typeof ContentSettingsZod>

const ActionDetailsZod = z.object({
  date: z.instanceof(Timestamp), // Assuming Timestamp is Date
  userId: z.string(),
  userName: z.string(),
  userRole: RoleZod,
})
export type ActionDetails = z.infer<typeof ActionDetailsZod>
const CreatedActionDetailsZod = z.object({
  ...ActionDetailsZod.shape,
  userRegion: RegionZod,
})
export type CreatedActionDetails = z.infer<typeof CreatedActionDetailsZod>

const UserActionsZod = z.object({
  edited: z.array(ActionDetailsZod),
  created: z.array(CreatedActionDetailsZod),
  published: z.array(ActionDetailsZod),
  reviewed: z.array(ActionDetailsZod),
})
export type UserActions = z.infer<typeof UserActionsZod>
//   {
//   edited: ActionDetails[]
//   created: (ActionDetails & { userRegion: Region })[]
//   published: ActionDetails[]
//   reviewed: ActionDetails[]
// }

export enum PublishStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

const PublishStatusZod = z.nativeEnum(PublishStatus)

export enum EditStatus {
  EDITING = "EDITING",
  NOT_EDITING = "NOT_EDITING",
}

const EditStatusZod = z.nativeEnum(EditStatus)

export enum ContentType {
  LOCAL_MAP = "LOCAL_MAP",
  TIMELINE = "TIMELINE",
  CONTRIBUTED = "CONTRIBUTED",
}

const ContentTypeZod = z.nativeEnum(ContentType)

export enum Classification {
  NATIONAL = "NATIONAL",
  REGIONAL = "REGIONAL",
  CONTRIBUTED = "CONTRIBUTED",
  FEATURED = "FEATURED"
}

const ClassificationZod = z.nativeEnum(Classification)


const ContentStatusZod = z.object({
  classification: ClassificationZod,
  contentTypes: z.array(ContentTypeZod),
  editStatus: EditStatusZod,
  showInteractiveDetails: z.optional(z.boolean()),
  publishStatus: PublishStatusZod,
  region: RegionZod, // Assuming Region is defined elsewhere
  // reviewFeedbacks: z.array(z.enum(Object.values(ReviewFeedback))),
  // reviewStatus: z.enum(Object.values(ReviewStatus)),
})
export type ContentStatus = {
  classification: Classification
  contentTypes: ContentType[]
  editStatus: EditStatus // allows an editing lock
  showInteractiveDetails?: boolean
  publishStatus: PublishStatus
  region: RegionEnum
  reviewFeedbacks: ReviewFeedback[]
  reviewStatus: ReviewStatus // allows a reviewStatus lock
}

export const ContentZod = z.object({
  docId: z.string(),
  id: z.string(),
  columns: z.array(ColumnZod),
  // rows: z.record(FieldZod), // Assuming FieldZod is defined elsewhere
  settings: ContentSettingsZod,
  status: ContentStatusZod,
})

export type Content = {
  deleted?: boolean
  docId: string
  id: string
  columns: Column[]
  rows: FieldRow
  settings: ContentSettings
  status: ContentStatus
  userActions: UserActions
  created: CreatedActionDetails
  lastUpdated: Timestamp
}

export type ContentCacheDoc = {
  data: string
  lastUpdated: Timestamp
  region: RegionEnum
}

export type Story = {
  id: string
  settings: ContentSettings
  status: ContentStatus
  [SectionEnum.TITLE]: TextArea
  [SectionEnum.SUBTITLE]: TextArea
  [SectionEnum.BODY]: TextArea
  [SectionEnum.IMAGE]: Image
  [SectionEnum.VIDEO]: Video
  [SectionEnum.NOTE]: TextArea
  created: ActionDetails
}

export type FieldRow = {
  [key: string]: Field
}

const KeyedContentZod = z.record(ContentZod)
export type KeyedContent = {
  [key: string]: Content
}

const ContentDocZod = z.object({
  content: KeyedContentZod,
  id: z.string(),
  type: ContentTypeZod,
})
export type ContentDoc = {
  content: KeyedContent // Object.fromEntries(Map object)
  id: string // timeline-0
  type: ContentType
}

const AllContentDocsZod = z.object({
  timeline: z.array(ContentDocZod),
  localMaps: z.array(ContentDocZod),
  allContributed: z.array(ContentDocZod),
  myContributed: z.array(ContentDocZod),
})
export type AllContentDocs = {
  timeline: ContentDoc[]
  localMaps: ContentDoc[]
  allContributed: ContentDoc[]
  myContributed: ContentDoc[]
}


export const ReviewStatusZod = z.string().optional().nullable()

export enum ReviewStatus {
  PASSED = "PASSED",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
}

export enum ReviewFeedback {
  DUPLICATE = "DUPLICATE",
  TYPO = "TYPO",
  ERROR = "ERROR",
  VIOLATION = "VIOLATION",
}

export const reviewFeedbackData: { label: string, description: string, identifier: ReviewFeedback }[] = [
  {
    label: "Duplicate",
    description: "This content has already been submitted",
    identifier: ReviewFeedback.DUPLICATE,
  },
  {
    label: "Has an Error",
    description: "This content seems to have an error.  Please check for accuracy and resubmit.  Contact us if you want to explain further",
    identifier: ReviewFeedback.ERROR,
  },
  {
    label: "Has a Typo",
    description: "The content has a typo or grammatical structure that makes it hard to understand.  Please check for readability and resubmit.",
    identifier: ReviewFeedback.TYPO,
  },
  {
    label: "In Violation",
    description: "The content is in violation of the site's Community Agreements (link) and/or reveals sensitive personal information.  Please check",
    identifier: ReviewFeedback.VIOLATION,
  },
]

