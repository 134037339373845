import { Component, computed, inject } from "@angular/core"
import { DecimalPipe } from "@angular/common"
import { RouteService } from "../../../services/route.service"
import { regionData } from "../../../regions/region.model"
import { EditorService } from "../services/editor.service"

@Component({
  imports: [
    DecimalPipe,
  ],
  standalone: true,
  template: `
    <div class="column wide">
      <div>
        Latitude: {{ location().lat | number: "1.2-2" }}
      </div>
      <div>
        Longitude: {{ location().lng | number: "1.2-2" }}
      </div>
    </div>
  `,
  selector: "e2e-editor-location",
})
export class EditorLocationComponent {
  private routeService = inject(RouteService)
  private editorService = inject(EditorService)

  location = computed(() => {
    const editorContentLocation = this.editorService.editorContent()?.settings.location
    if (editorContentLocation && editorContentLocation.lat !== null && editorContentLocation.lng !== null) {
      return editorContentLocation
    }
    return regionData[this.routeService.region()]?.location || { lng: 0, lat: 0 }
  })
  // locationLat = computed(() => this.editorContent().settings.location.lat)
  // locationLng = computed(() => this.editorContent().settings.location.lng)
}
