import { Component, computed, inject, output } from "@angular/core"
import { ProfileService } from "../profile/profile.service"
import { ProfileProgress } from "../profile/profile.model"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    @if (profileStatus()) {
      <div
        class="column"
        style="align-items: center; justify-content: center"
      >
        @if (profileIncomplete()) {
          <div
            class="column"
            style="padding: 10px; outline: solid 3px red; border-radius: 10px"
          >
            <div class="center">
              Some required fields are needed before you can submit a Story
            </div>
          </div>
        }
        <!--
                  <div class="font-bold">
                    Profile is {{ percent() }}% complete
                  </div>
                  <div class="w-full">
                    <progress
                      class="progress w-full"
                      [class]="profileIncomplete() ? 'progress-accent' : 'progress-secondary'"
                      [value]="percent()"
                      max="100"
                    ></progress>
                  </div>
        -->
        @if (profileRequired()) {
          <div>
            Your basic profile is complete! You may now upload your written, video, and/or audio stories to share with
            others. We also invite you to complete more of your profile, which is optional and helps the UDTR team
            understand more about which communities are engaging this project.
          </div>
          <button
            class="btn"
            (click)="cancelAndClose.emit()"
            >
            Close Profile
          </button>
        }
      </div>
    }
  `,
  selector: "e2e-profile-status",
})
export class ProfileStatusComponent {
  private profileService = inject(ProfileService)

  cancelAndClose = output()

  profileStatus = this.profileService.profileStatus
  percent = computed(() => this.profileStatus().percent)
  profileIncomplete = computed(() => this.profileStatus().progress === ProfileProgress.INCOMPLETE)
  profileRequired = computed(() => this.profileStatus().progress === ProfileProgress.REQUIRED)
}
