import { Component, computed, inject, input, signal } from "@angular/core"
import { Video } from "../fields.type"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    @if (videoUrl()) {
      <video width="600" controls>
        <source [src]="videoUrl()" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    }
  `,
  selector: "e2e-video-view",
})
export class VideoViewComponent {

  videoUrl = input.required<string>()
  // fileUrl = computed(
  //   () =>
      // this.domSanitizer.bypassSecurityTrustResourceUrl(
      // this.svgGraphicsService.svgGraphic(this.imageView().svgGraphics) &&
      // this.video().filePath,
    // ),
  // )
}
