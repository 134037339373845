import { Component, computed, inject, untracked } from "@angular/core"
import { TimelineItemComponent } from "./timeline-item.component"
import { FirestoreReadService } from "../../../content/firestore-read.service"
import { Classification, ContentType } from "../../../content/content.model"
import { RouteService } from "../../../../services/route.service"
import { TimelineService } from "../timeline.service"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    TimelineItemComponent,
    UiInheritDirective
  ],
  standalone: true,
  template: `
    <div class="sr-only">
      Use arrow keys to navigate through the content items.
    </div>
    @for (content of allTimelineContent_array(); track content.id) {
      <e2e-timeline-item [content]="content" inherit/>
    }
  `,
  selector: "e2e-timeline-items",
})
export class TimelineItemsComponent {
  private firestoreReadService = inject(FirestoreReadService)
  private routeService = inject(RouteService)
  private timelineService = inject(TimelineService)

  allTimelineContent_array = computed(() => {
    const results = this.firestoreReadService
      .arrayFromContent()
      .filter((content) => content.status.contentTypes.includes(ContentType.TIMELINE))
      .filter(
        (content) =>
          content.status.classification === Classification.NATIONAL ||
          (content.status.classification === Classification.REGIONAL &&
            content.status.region === this.routeService.region()) ||
          (content.status.classification === Classification.FEATURED &&
            content.status.region === this.routeService.region())
      )
      .sort((a, b) => {
        const groupA = a.settings.position.top.px < 690 ? 1 : a.settings.position.top.px < 870 ? 2 : 3
        const groupB = b.settings.position.top.px < 690 ? 1 : b.settings.position.top.px < 870 ? 2 : 3
        const distA = Math.hypot(a.settings.position.top.px, a.settings.position.left.px + 10000)
        const distB = Math.hypot(b.settings.position.top.px, b.settings.position.left.px + 10000)
        if (groupA !== groupB) {
          return groupA - groupB
        } else {
          return distA - distB
        }
      })

    if (results.length > 0) {
      untracked(() => this.timelineService.selectItem(results[0].id))
    }
    return results
  })
}
