import { Component, output, signal } from "@angular/core"
import { UiDialogComponent } from "ui/dialog"

@Component({
  imports: [
    UiDialogComponent,
  ],
  standalone: true,
  template: `
    @if (show()) {
      <div (click)="$event.stopPropagation()">
        <lib-ui-dialog (cancelAndClose)="closeAction()">
          <div ngProjectAs="title">
            HOLC Attribution
          </div>

          <div ngProjectAs="content">

            <div class="column" style="align-items: center">
              <div>
                The source materials for these HOLC area descriptions can be accessed in the
                <a
                  target="_blank"
                  href="https://catalog.archives.gov/id/720357"
                  style="text-decoration: underline"
                >
                  City Survey Files, 1935-1940
                </a>
                at the National Archives. The data was graciously compiled and shared with our team for this project by
                the
                <a
                  target="_blank"
                  href="https://dsl.richmond.edu/panorama/redlining/data"
                  style="text-decoration: underline"
                >
                  Mapping Inequality
                </a>
                project at the University of Richmond.
              </div>
              <div class="row">
                <button
                  class="btn btn-sm green"
                  (click)="closeAction()"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </lib-ui-dialog>

      </div>
    }
  `,
  selector: "e2e-holc-attribution-dialog",
})
export class HolcAttributionDialogComponent {
  confirmed = output()
  show = signal(true)

  closeAction() {
    this.show.set(false)
    this.acceptAction()
  }

  acceptAction() {
    this.confirmed.emit()
  }
}
