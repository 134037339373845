import { Component, inject } from "@angular/core"
import { UiAlertService } from "./ui-alert.service"
import { faWarning } from "@fortawesome/pro-solid-svg-icons"
import { UiIconComponent } from "ui/icon"

@Component({
  imports: [
    UiIconComponent,
  ],
  standalone: true,
  template: `
    @if (show()) {
      <div role="alert" class="alert">
        <ng-content select="icon">
          <lib-ui-icon [icon]="faWarning"/>
        </ng-content>
        <ng-content select="content-left">
          {{ contentLeft() }}
        </ng-content>
        <ng-content select="content-right"></ng-content>
      </div>
    }
  `,
  selector: "lib-ui-alert",
})
export class UiAlertComponent {
  private uiAlertService = inject(UiAlertService)

  show = this.uiAlertService.show
  contentLeft = this.uiAlertService.contentLeft
  contentRight = this.uiAlertService.contentRight

  protected readonly faWarning = faWarning
}
