import { Component, output, signal } from "@angular/core"
import { UiDialogComponent } from "ui/dialog"

@Component({
  imports: [
    UiDialogComponent,
  ],
  standalone: true,
  template: `
    @if (show()) {
      <div (click)="$event.stopPropagation()">
        <lib-ui-dialog (cancelAndClose)="closeAction()">
          <div ngProjectAs="title">
            Please Confirm
          </div>

          <div ngProjectAs="content">
            <div class="column">
              <div class="center">
                Save new location?
              </div>
              <div class="row" style="justify-content: space-between">
                <button
                  class="btn btn-sm gray"
                  (click)="closeAction()"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-sm green"
                  (click)="acceptAction()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </lib-ui-dialog>

      </div>
    }
  `,
  selector: "e2e-confirm-new-map-location-dialog",
})
export class ConfirmNewMapLocationDialogComponent {
  cancelled = output()
  confirmed = output()

  show = signal(true)

  closeAction() {
    this.show.set(false)
    this.cancelled.emit()
  }

  acceptAction() {
    this.confirmed.emit()
  }

}
