import { Component, output } from "@angular/core"
import { UiDialogComponent } from "ui/dialog"

@Component({
  imports: [
    UiDialogComponent,
  ],
  standalone: true,
  template: `
    <lib-ui-dialog [alignTitle]="'left'" (cancelAndClose)="close.emit()">
      <div ngProjectAs="title">
        Welcome to the Interactive Map
      </div>
      <div ngProjectAs="content">
        <div class="column">
          <div>
            Click on a zone to see an excerpt from its HOLC redlining description.
            You can drag or zoom around this map to explore the city's different zones. You can also pinch or rotate
            using two fingers, just like in other interactive maps.
          </div>
          <div>
            If you are using a keyboard, you can move with ←↑→↓ arrow keys and zoom in/out with +- keys. You can also
            rotate the map by right clicking and dragging.
          </div>
          <div>
            To see this message again, just click on the information icon at the top right of the screen.
          </div>
          <div class="row">
            <button
              class=" btn btn-primary"
              (click)="close.emit()"
            >
              I'm ready to explore the map
            </button>
          </div>
        </div>
      </div>
    </lib-ui-dialog>
  `,
  selector: "lib-feature-help-tips-redline-map",
})
export class HelpTipsRedlineMapComponent {

  close = output()

}
