import { IntroSubContent } from "../components/pages/intro/intro.model"
import { inject, Injectable } from "@angular/core"
import { RegionService } from "./region.service"
import { RegionConfig } from "./region.model"

@Injectable({
  providedIn: "root",
})
export class BostonMaRegion {

  config(): RegionConfig {
    return {
      audioTour: false,
      firewall: false,
    }
  }

  localVideo(): IntroSubContent {
    return {
      image: {
        src: "",
        style: {},
      },
      video: {},
      style: {},
      contents: [],
      subTitle: "",
      value: "",
    }
  }

  brandHeader(): IntroSubContent {
    return {
      contents: [],
      video: {},
      title: "Boston",
      subTitle: "presented in partnership with",
      image: {
        altText: "NAR logo",
        src: "/assets/clients/boston_ma/Boston-Logo.png",
        style: {},
      },
      style: {
        width: "300px",
      },
    }
  }

}
