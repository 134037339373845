import { Component, computed, inject, signal } from "@angular/core"
import { EditorService } from "../services/editor.service"
import { SaveIconComponent } from "../../shared/save-icon.component"
import { UiCheckboxComponent } from "ui/checkbox"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    SaveIconComponent,
    UiInheritDirective,
    UiCheckboxComponent,
  ],
  standalone: true,
  template: `
    <div class="row">
      <lib-ui-checkbox
        [name]="name"
        [selected]="selected()"
        label="Show Interactive Details"
        identifier="interactive-details"
        (changes)="changes($event)"
      />
      <e2e-save-icon [modelChange]="modelChange()"/>
    </div>
  `,
  selector: "e2e-editor-interactive-details",
})
export class EditorInteractiveDetailsComponent {
  private editorService = inject(EditorService)

  name = "interactive-details"

  selected = computed<boolean>(() => !!this.editorService.editorContent()?.status.showInteractiveDetails)
  modelChange = signal<number>(0)

  changes(showInteractiveDetails: boolean) {
    this.modelChange.set(Date.now())
    const content = this.editorService.editorContent()
    if (content) {
      this.editorService.queueEditor({
        ...content,
        status: {
          ...content.status,
          showInteractiveDetails,
        },
      })
    }
  }
}
