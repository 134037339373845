import { inject, Injectable } from "@angular/core"
import { AllContentDocs, Content, ContentDoc, ContentType } from "./content.model"
import { collection, deleteDoc, doc, Firestore, Timestamp, writeBatch, WriteBatch } from "@angular/fire/firestore"
import { FirestoreReadService } from "./firestore-read.service"

export type GroupDoc = {
  content: {
    [key: string]: Content
  }
  id: string // timeline-0
  type: ContentType
}

const documentCollection = "content"
const documentSizeLimit = 5000

@Injectable({
  providedIn: "root",
})
export class FirestoreWriteService {
  private firestore = inject(Firestore)
  private firestoreReadService = inject(FirestoreReadService)

  private batchDelete(batch: WriteBatch, contentDoc: ContentDoc) {
    if (Object.keys(contentDoc.content).length === 0) {
      batch.delete(doc(collection(this.firestore, documentCollection), contentDoc.id))
    }
    return batch
  }

  private setContentGroupDoc(batch: WriteBatch, group: GroupDoc) {
    batch.set(doc(collection(this.firestore, documentCollection), group.id), group, { merge: true })
    return batch
  }

  deleteContent(contentId: string) {
    this.firestoreReadService.updateRegionCache.set(true)
    return deleteDoc(doc(collection(this.firestore, documentCollection), contentId))
  }

  saveContent(contentArray: Content[]): Promise<any[]> {
    this.firestoreReadService.updateRegionCache.set(true)
    const promises: Promise<any>[] = []
    const batchSize = 30
    const chunks = Array
      .from({ length: Math.ceil(contentArray.length / batchSize) })
      .map((_, index) =>
        contentArray.slice(index * batchSize, index * batchSize + batchSize))
    chunks.forEach(chunk => {
      const batch = writeBatch(this.firestore)
      chunk.forEach(content => {
        if (content.id) {
          // console.log(content)
          batch.set(
            doc(collection(this.firestore, documentCollection), content.id),
            {
              ...content,
              lastUpdated: Timestamp.now(),
            },
            { merge: true },
          )
        }
      })
      promises.push(batch.commit())
    })
    return Promise.all(promises)
  }

  saveContentPromise(contentArray: Content[]) {
    const allContentDocs = this.firestoreReadService.allContentDocsByType
    const docs = { ...allContentDocs() }

    let batch = writeBatch(this.firestore)
    const allAddContentTypes: ContentType[] = []
    const allDeleteContentTypes: ContentType[] = []

    contentArray.forEach(content => {
      const contributedIndex = content.status.contentTypes.indexOf(ContentType.CONTRIBUTED)
      const hasContributed = contributedIndex !== -1

      /*
            if (passedReview && hasContributed) {
              content.settings.types = [
                ...content.settings.types.slice(0, contributedIndex),
                ...content.settings.types.slice(contributedIndex + 1)
              ]
            }
            if (!passedReview && !hasContributed) { // all that are not approved are moved into contributed
              content.settings.types = [
                ...content.settings.types,
                ContentType.CONTRIBUTED
              ]
            }
      */

      const allTypes = Object.keys(ContentType) as ContentType[]
      const addContentTypes: ContentType[] = []
      const deleteContentTypes: ContentType[] = []
      for (const contentType of allTypes) {
        /**
         * if content has CONTRIBUTED
         * save content to CONTRIBUTED docs (userId docs) and remove from all other docs (LOCAL_MAPS and TIMELINE)
         */
        if (hasContributed) {
          contentType === ContentType.CONTRIBUTED ? addContentTypes.push(contentType) : deleteContentTypes.push(contentType)
        }
        /**
         * is content does not hae CONTRIBUTED
         * add and remove from docs as defined in content.settings.types array
         */
        if (!hasContributed) {
          content.status.contentTypes.includes(contentType) ? addContentTypes.push(contentType) : deleteContentTypes.push(contentType)
        }
      }
      batch = this.deleteContentWithBatch(batch, content, deleteContentTypes, docs)
      batch = this.addContentWithBatch(batch, content, addContentTypes, docs)
      addContentTypes.map(contentType => allAddContentTypes.push(contentType))
      deleteContentTypes.map(contentType => allDeleteContentTypes.push(contentType))
    })


    /**
     * garbage collection has the risk of
     * removing a doc that was previously empty
     * but is currently getting content added to it
     * because it only considers initial conditions
     *
     * this approach is to avoid garbage collection on content types that are getting content added to them
     */
    if (!allAddContentTypes.includes(ContentType.CONTRIBUTED)) {
      batch = this.garbageCollection(batch, allDeleteContentTypes, docs)
    }

    return batch.commit()
  }

  private deleteContentWithBatch(writeBatch: WriteBatch, content: Content, contentTypes: ContentType[], docs: AllContentDocs) {
    let batch = writeBatch
    let groups: GroupDoc[] = []
    for (const contentType of contentTypes) {
      switch (contentType) {
        // case ContentType.INTRODUCTION:
        //   groups = docs.introGroups
        //   break
        // case ContentType.UNDESIGN:
        //   groups = this.dataContentService.undesignGroups
        //   break
        case ContentType.TIMELINE:
          groups = docs.timeline
          break
        case ContentType.CONTRIBUTED:
          groups = docs.allContributed.length
            ? docs.allContributed
            : docs.myContributed
          break
        case ContentType.LOCAL_MAP:
          groups = docs.localMaps
          break
      }
      groups.map(group => {
        Object.keys(group.content).map(contentId => {
          if (contentId === content.id) {
            delete group.content[contentId]
            batch = this.setContentGroupDoc(batch, group)
          }
        })
      })
    }
    return batch
  }

  private addContentWithBatch(writeBatch: WriteBatch, content: Content, contentTypes: ContentType[], docs: AllContentDocs) {
    let batch = writeBatch
    for (const contentType of contentTypes) {
      switch (contentType) {
        // case ContentType.INTRODUCTION:
        //   batch = this.saveContentToGroups(batch, docs.introGroups, content, contentType)
        //   break
        // case ContentType.UNDESIGN:
        //   batch = this.saveContentToGroups(batch, this.dataContentService.undesignGroups, content, contentType)
        //   break
        case ContentType.TIMELINE:
          batch = this.saveContentToGroups(batch, docs.timeline, content, contentType)
          break
        case ContentType.LOCAL_MAP:
          batch = this.saveContentToGroups(batch, docs.localMaps, content, contentType)
          break
        case ContentType.CONTRIBUTED: {
          /**
           * length === 0 when user is not Contributor, Editor, Moderator or Admin
           * use allContributedGroups for editors
           * user userContributedGroups for non-editors
           */
          const contributedGroups = docs.allContributed.length
            ? docs.allContributed
            : docs.myContributed
          batch = this.saveContentToContributedGroup(batch, contributedGroups, content)
        }
      }
    }
    return batch
  }

  /**
   * removes docs with no content in the content object
   */
  private garbageCollection(writeBatch: WriteBatch, contentTypes: ContentType[], docs: AllContentDocs) {
    let batch = writeBatch
    contentTypes.map(contentType => {
      switch (contentType) {
        case ContentType.TIMELINE:
          docs.timeline.map(contentDoc => (batch = this.batchDelete(batch, contentDoc)))
          break
        case ContentType.LOCAL_MAP:
          docs.localMaps.map(contentDoc => (batch = this.batchDelete(batch, contentDoc)))
          break
        case ContentType.CONTRIBUTED:
          docs.allContributed.map(contentDoc => (batch = this.batchDelete(batch, contentDoc)))
          docs.myContributed.map(contentDoc => (batch = this.batchDelete(batch, contentDoc)))
      }
    })
    return batch
  }

  private saveContentToGroups(writeBatch: WriteBatch, groups: GroupDoc[], content: Content, contentType: ContentType) {
    let batch = writeBatch
    let modifiedGroup: GroupDoc | undefined
    let modifiedGroupId: string | undefined

    let docAdded = false
    const groupName = contentType.toLowerCase().replace("_", "-")

    groups.forEach(group => {
      if (group.content[content.id]) {
        group.content[content.id] = content
        modifiedGroup = group
        modifiedGroupId = group.id
        docAdded = true
      }
    })

    if (!docAdded) {
      for (const group of groups) {
        if (group.type === contentType) {
          if (!docAdded) {
            const sizeLimit = documentSizeLimit
            const groupSize = JSON.stringify(group).length
            const containerSize = JSON.stringify(content).length
            const newSize = groupSize + containerSize
            /**
             * Add doc to an existing group if we don't exceed the size limit.
             */
            // console.log(sizeLimit - newSize)
            if (newSize < sizeLimit) {
              group.content[content.id] = content
              modifiedGroup = group
              modifiedGroupId = group.id
              docAdded = true
            }
          }
        }
      }
    }

    /**
     * Add fragment to a new group if none of the existing groups have room for it.
     */
    if (!docAdded) {
      const groupId = groupName + "-" + groups.length.toString()
      modifiedGroup = {
        id: groupId,
        content: {
          [content.id]: content,
        },
        type: contentType,
      }
      modifiedGroupId = groupId
    }

    if (modifiedGroup && modifiedGroupId) {
      batch = this.setContentGroupDoc(batch, modifiedGroup)
    }
    return batch
  }

  private saveContentToContributedGroup(writeBatch: WriteBatch, groups: GroupDoc[], content: Content) {
    let batch = writeBatch
    const userId: string | undefined = content.userActions.created[0]?.userId
    if (userId) {
      const group: GroupDoc = groups
          .find(group => group.id === userId)
        || {
          id: userId,
          content: {
            [content.id]: content,
          },
          type: ContentType.CONTRIBUTED,
        }
      const sizeLimit = documentSizeLimit
      const groupSize = JSON.stringify(group).length
      if (groupSize < sizeLimit) {
        group.content[content.id] = content
        batch = this.setContentGroupDoc(batch, group)
      } else {
        /**
         * notification
         */
        console.error("document size limit has been reached")
      }
    }
    return batch
  }

}
