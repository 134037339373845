import { Component, computed, inject, input, OnInit, output, signal } from "@angular/core"
import { Content, ContentType, EditStatus, PublishStatus, ReviewStatus } from "../content/content.model"
import { RouteService } from "../../services/route.service"
import { EditorContext, EditorService } from "./services/editor.service"
import { EditorHeaderComponent } from "./editor-header.component"
import { EditorAdminComponent } from "./editor-admin.component"
import { ProfileService } from "../profile/profile.service"
import { EditorContentComponent } from "./editor-content.component"
import { UiDialogComponent } from "ui/dialog"
import { UiInheritDirective } from "ui/inherit"
import { EditorFooterComponent } from "./editor-footer.component"
import { Timestamp } from "@angular/fire/firestore"
import { UiIconComponent } from "ui/icon"
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons"

@Component({
  imports: [
    EditorHeaderComponent,
    EditorAdminComponent,
    EditorContentComponent,
    UiInheritDirective,
    UiDialogComponent,
    EditorFooterComponent,
    UiIconComponent,
  ],
  standalone: true,
  template: `
    <lib-ui-dialog
      (cancelAndClose)="cancelAndClose()"
      [scrollableBackground]="scrollableBackground()"
    >
      <div ngProjectAs="title">
        @if (showContent()) {
          <e2e-editor-header
            [context]="context()"
            (saveAndClose)="saveAndClose()"
            (deleteAndClose)="deleteAndClose()"
            inherit
          />
        }
      </div>
      <div ngProjectAs="content">
        @if (showSpinner()) {
          <div class="text-8xl center">
            <lib-ui-icon [icon]="faSpinner" animation="spin-pulse"/>
          </div>
        }
        @if (showContent()) {
          <div class="column">
            @if (adminContext()) {
              <e2e-editor-admin inherit/>
            }
            @if (!adminContext()) {
              <e2e-editor-content [context]="context()" inherit/>
            }
          </div>
        }
      </div>
      <div ngProjectAs="footer">
        @if (showContent()) {
          <e2e-editor-footer
            [context]="context()"
            (approveAndClose)="approveAndClose()"
            (rejectAndClose)="rejectAndClose()"
            (resubmitForReviewAndClose)="resubmitForReviewAndClose()"
            (saveAndClose)="saveAndClose()"
            (deleteAndClose)="deleteAndClose()"
            inherit
          />
        }
      </div>
    </lib-ui-dialog>
  `,
  selector: "e2e-editor",
})
export class EditorComponent implements OnInit {
  private editorService = inject(EditorService)
  private routeService = inject(RouteService)
  private profileService = inject(ProfileService)

  context = input.required<EditorContext>()
  content = input.required<Content>() // pre-edit content, in ngOnInit content is modified and pushed to editorContent
  isNewContent = input(false)
  scrollableBackground = input<boolean>(false)

  close = output()

  loadingEditor = signal(false)
  closingEditor = signal(false)
  showSpinner = computed(() => this.loadingEditor() || this.closingEditor())
  showContent = computed(() => !this.loadingEditor() && !this.closingEditor())

  adminContext = computed(() => this.context() === "admin")
  contributorContext = computed(() => this.context() === "contributor")
  contentContext = computed(() => this.context() === "content")
  moderationContext = computed(() => this.context() === "moderation")
  myContentContext = computed(() => this.context() === "myContent")

  section = this.routeService.section

  ngOnInit() {
    this.loadingEditor.set(true)
    this.editorService.clearEditor()
    let content = this.content()
    // debugger
    switch (this.context()) {
      case "moderation":
        content = {
          ...content,
          status: {
            ...content.status,
            editStatus: EditStatus.NOT_EDITING,
            reviewStatus: content.status.reviewStatus === ReviewStatus.FAILED ? ReviewStatus.FAILED : ReviewStatus.ACTIVE,
          },
          userActions: {
            ...content.userActions,
            reviewed: [
              {
                date: Timestamp.now(),
                userId: this.profileService.userId(),
                userName: this.profileService.userName(),
                userRole: this.profileService.userRole(),
              },
              // ...content.userActions.reviewed.slice(-4),
            ],
          }
        }
        break
      case "admin":
      case "contributor":
      case "myContent":
      default:
        if (!this.isNewContent()) {
          content = {
            ...content,
            status: {
              ...content.status,
              editStatus: EditStatus.EDITING,
            },
            userActions: {
              ...content.userActions,
              edited: [
                {
                  date: Timestamp.now(),
                  userId: this.profileService.userId(),
                  userName: this.profileService.userName(),
                  userRole: this.profileService.userRole(),
                },
                // ...content.userActions.edited.slice(-10),
              ],
            },
          }
        }
    }
    if (this.isNewContent()) {
      this.editorService.loadEditor(content)
      this.loadingEditor.set(false)
    } else {
      this.editorService.saveContent(content)
        .then(() => {
          this.editorService.loadEditor(content)
          this.loadingEditor.set(false)
        })
    }
  }

  approveAndClose() {
    const content = this.editorService.editorContent()
    if (content) {
      this._saveAndClose({
        ...content,
        status: {
          ...content.status,
          publishStatus: PublishStatus.PUBLISHED,
          reviewStatus: ReviewStatus.PASSED,
          contentTypes: this.editorService.removeContentType(content, ContentType.CONTRIBUTED),
        },
      })
    }
  }

  rejectAndClose() {
    const content = this.editorService.editorContent()
    if (content) {
      this._saveAndClose({
        ...content,
        status: {
          ...content.status,
          publishStatus: PublishStatus.UNPUBLISHED,
          reviewStatus: ReviewStatus.FAILED,
          contentTypes: this.editorService.addContentType(content, ContentType.CONTRIBUTED),
        },
      })
    }
  }

  resubmitForReviewAndClose() {
    const content = this.editorService.editorContent()
    if (content) {
      this._saveAndClose({
        ...content,
        status: {
          ...content.status,
          editStatus: EditStatus.NOT_EDITING,
          reviewStatus: ReviewStatus.PENDING,
        },
      })
    }
  }

  saveAndClose() {
    const content = this.editorService.editorContent()
    if (content) {
      this._saveAndClose({
        ...content,
        status: {
          ...content.status,
          editStatus: EditStatus.NOT_EDITING,
          reviewStatus: content.status.reviewStatus === ReviewStatus.ACTIVE ? ReviewStatus.PENDING : content.status.reviewStatus,
        },
      })
    }
  }

  deleteAndClose() {
    const content = this.editorService.editorContent()
    if (content) {
      this._saveAndClose({
        ...content,
        deleted: true,
      })
    }
  }

  cancelAndClose() {
    if (this.isNewContent()) {
      this.editorService.clearEditor()
      this.close.emit()
      return
    }
    const content = this.content()
    this._saveAndClose({
      ...content,
      status: {
        ...content.status,
        editStatus: EditStatus.NOT_EDITING,
        reviewStatus: content.status.reviewStatus === ReviewStatus.ACTIVE ? ReviewStatus.PENDING : content.status.reviewStatus,
      },
    })
  }

  private _saveAndClose(content: Content) {
    this.closingEditor.set(true)
    this.editorService.incrementSaveCounter()
    this.editorService.saveContent(content)
      .then(() => {
        this.editorService.clearEditor()
        this.closingEditor.set(false)
        this.close.emit()
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.editorService.decrementSaveCounter()
      })
  }

  protected readonly faSpinner = faSpinner
}
