import { Component, inject, input, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { ProfileService } from "../profile.service"
import { UiInheritDirective } from "ui/inherit"
import { UiTextareaComponent } from "ui/textarea"
import { ProfileEditorService } from "../profile-editor.service"
import { ProfileSaveIconComponent } from "../../shared/profile-save-icon.component"

@Component({
  imports: [
    FormsModule,
    UiInheritDirective,
    UiTextareaComponent,
    ProfileSaveIconComponent,
  ],
  standalone: true,
  template: `
    <div class="column gap-0">
      <div class="font-bold">
        Please share a short title to communicate a little bit about who you are (e.g. “63 year resident of South
        Bend”, “Urban Planning Student at U. Baltimore”, or “baker on Main Street”)
        <e2e-profile-save-icon [modelChange]="modelChange()"/>
      </div>
      <lib-ui-textarea
        [rows]="1"
        placeholder="title"
        class="w-full"
        [value]="title()"
        (changes)="changes($event)"
      />
    </div>
  `,
  selector: "e2e-profile-title",
})
export class ProfileTitleComponent {
  private profileService = inject(ProfileService)
  private profileEditorService = inject(ProfileEditorService)

  autosave = input(false)

  title = this.profileService.title
  modelChange = signal<number | undefined>(undefined)

  changes(title: string) {
    this.modelChange.set(Date.now())
    const profile = this.profileEditorService.profileEditor() || this.profileService.profile()
    if (profile) {
      this.profileEditorService.saveToProfile(
        {
          ...profile,
          title,
        },
        this.autosave(),
      )
    }
  }
}
