import { Component, inject, input, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { ProfileService } from "../profile.service"
import { UiInheritDirective } from "ui/inherit"
import { UiTextareaComponent } from "ui/textarea"
import { ProfileEditorService } from "../profile-editor.service"
import { ProfileSaveIconComponent } from "../../shared/profile-save-icon.component"

@Component({
  imports: [
    FormsModule,
    UiInheritDirective,
    UiTextareaComponent,
    ProfileSaveIconComponent,
  ],
  standalone: true,
  template: `
    <div class="column gap-0">
      <div class="font-bold">
        Profile Name
        <e2e-profile-save-icon [modelChange]="modelChange()"/>
      </div>
      <div class="text-sm">
        The Profile Name will be visible to other users. Please be careful not to share private information.
      </div>
      <lib-ui-textarea
        [rows]="1"
        placeholder="username"
        class="w-full"
        [value]="userName()"
        (changes)="changes($event)"
      />
    </div>
  `,
  selector: "e2e-profile-user-name",
})
export class ProfileUserNameComponent {
  private profileService = inject(ProfileService)
  private profileEditorService = inject(ProfileEditorService)

  autosave = input(false)

  userName = this.profileService.userName
  modelChange = signal<number | undefined>(undefined)

  changes(userName: string) {
    this.modelChange.set(Date.now())
    const profile = this.profileEditorService.profileEditor() || this.profileService.profile()
    if (profile) {
      this.profileEditorService.saveToProfile(
        {
          ...profile,
          userName,
        },
        this.autosave())
    }
  }
}
