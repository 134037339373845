import { LinkTargetType } from "@shared"
import { SvgGraphics } from "../../pages/timeline/timeline.svg-graphics.service"

export type TextMode = "edit" | "view" | "review"

export enum FieldType {
  IMAGE = "IMAGE",
  TEXT_AREA = "TEXT_AREA",
  VIDEO = "VIDEO",
}

export enum SectionEnum {
  BODY = "BODY",
  TITLE = "TITLE",
  SUBTITLE = "SUBTITLE",
  NOTE = "NOTE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  WYSIWYG = "WYSIWYG",
}

export type Field =
  | TextArea
  | Image
  | Video

export type TextArea = {
  rowId: string
  field: FieldType
  section: SectionEnum
  style: TextAreaStyleType
  value: string
}

export type TextStyles = {
  classes?: string
  display?: "none"
  fontFamily?: string
  fontSize_px?: 18 | 20 | 30 | 50 | 80 | 110 | 140
  fontStyle?: "italic" | "normal"
  fontWeight?: 500 | 900
  lineHeight_pct?: 90 | 100
  textTransform?: "uppercase" | "none"
}

export type TextAreaStyleBase = {
  label: string
  section: SectionEnum
  style: TextAreaStyleType
}

export type TextAreaStyle = TextAreaStyleBase & TextStyles

export enum TextAreaStyleType {
  STYLE_1 = "STYLE_1",
  STYLE_2 = "STYLE_2",
  STYLE_3 = "STYLE_3",
  STYLE_4 = "STYLE_4",
  STYLE_5 = "STYLE_5",
  HIDDEN = "HIDDEN",
  NORMAL = "NORMAL",
  ITALIC = "ITALIC",
  QUOTE = "QUOTE",
  WYSIWYG = "WYSIWYG",
  PLAIN_TEXT = "PLAIN_TEXT",
}

export type Image = {
  rowId: string
  alt: string
  field: FieldType
  filePath: string
  fileType: string
  section: SectionEnum.IMAGE
  svgGraphics: SvgGraphics | null
  targetType: LinkTargetType
  targetPath: string
}

export type ImageFileType = "image/png" | "image/jpeg" | "image/gif" | ""

export type ImageView = Image & { contentWidth: number }

export type Video = {
  rowId: string
  alt: string
  field: FieldType
  filePath: string
  fileType: string
  section: SectionEnum.VIDEO
  svgGraphics: SvgGraphics | null
  targetType: LinkTargetType
  targetPath: string
}

export type VideoFileType = "video/mp4" | "video/ogg" | "video/webm" | ""
