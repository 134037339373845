import { Classification, Content } from "../../content/content.model"
import { effect, inject, Injectable, signal, untracked } from "@angular/core"
import { Styles } from "./timeline.model"
import { EditorService } from "../../editor/services/editor.service"

@Injectable({
  providedIn: "root"
})
export class TimelineService {
  private editorService = inject(EditorService)

  background = signal(false)
  bookmarksDemo = signal(false)
  private _interactiveMode = signal(true)
  interactiveMode = this._interactiveMode.asReadonly()
  private _scale = signal(1)
  scale = this._scale.asReadonly()
  dragging = signal(false)

  private _selectedId = signal("")
  selectedId = this._selectedId.asReadonly()
  pageHeight = 1400
  pageWidth = 4000

  constructor() {
    effect(() => {
      const background = this.background()
      untracked(() => {
        if (background) this.setScale(1)
      })
    })
  }

  setScale(scale: number) {
    this._scale.set(scale)
  }

  getStyles(content: Content | undefined) {
    const styles = {} as Styles
    if (content) {
      const settings = content.settings
      styles.positionTop = settings.position.top.px
      if (styles.positionTop > 660) {
        // styles.positionTop = styles.positionTop + 50
      }
      styles.positionLeft = settings.position.left.px
      styles.zIndex = settings.position.layer
      styles.transform = "rotate(" + settings.rotation.deg.toString() + "deg)"
      styles.width = settings.width.px
      if (settings.width.px === -1) styles.width = undefined
    }
    return styles
  }

  selectItem(selectedId: string) {
    this._selectedId.set(selectedId)
  }

  deselectItem() {
    this._selectedId.set("")
  }

  createTimeline(classification: Classification, fromContent?: Content) {
    // this.editorService.createTimelineContent(classification, fromContent)
  }

  setInteractiveMode(interactiveMode: boolean) {
    this._interactiveMode.set(interactiveMode)
  }

}
