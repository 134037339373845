import { Component, inject, input, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { ProfileService } from "../profile.service"
import { UiInheritDirective } from "ui/inherit"
import { UiTextareaComponent } from "ui/textarea"
import { ProfileEditorService } from "../profile-editor.service"
import { ProfileSaveIconComponent } from "../../shared/profile-save-icon.component"

@Component({
  imports: [
    FormsModule,
    UiInheritDirective,
    UiTextareaComponent,
    ProfileSaveIconComponent,
  ],
  standalone: true,
  template: `
    <div class="column gap-0">
      <div class="font-bold">
        Where’s home?
        <e2e-profile-save-icon [modelChange]="modelChange()"/>
      </div>
      <lib-ui-textarea
        [rows]="1"
        placeholder="homeLocation"
        class="w-full"
        [value]="homeLocation()"
        (changes)="changes($event)"
      />
    </div>
  `,
  selector: "e2e-profile-home-location",
})
export class ProfileHomeLocationComponent {
  private profileService = inject(ProfileService)
  private profileEditorService = inject(ProfileEditorService)

  autosave = input(false)

  homeLocation = this.profileService.homeLocation
  modelChange = signal<number | undefined>(undefined)

  changes(homeLocation: string) {
    this.modelChange.set(Date.now())
    const profile = this.profileEditorService.profileEditor() || this.profileService.profile()
    if (profile) {
      this.profileEditorService.saveToProfile({
        ...profile,
        homeLocation
      }, this.autosave())
    }
  }
}
