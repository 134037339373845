import { collection, Firestore, onSnapshot, Unsubscribe } from "@angular/fire/firestore"
import { computed, effect, inject, Injectable, signal, untracked } from "@angular/core"
import { ProfileService, Role } from "../profile/profile.service"
import { environment } from "../../../environments/environment"
import { map } from "rxjs"
import { Profile } from "../profile/profile.model"
import { HttpClient } from "@angular/common/http"
import { auth } from "firebase-admin"
import UserRecord = auth.UserRecord

@Injectable({
  providedIn: "root",
})
export class UserManagerService {
  private firestore = inject(Firestore)
  private profileService = inject(ProfileService)
  private httpClient = inject(HttpClient)

  private baseUrl = environment.baseUrl + "/api-v1"

  // userRecords = signal<UserRecord[]>([])

  isModerator = this.profileService.isModerator

  allUserAccounts_Unsubscribe: Unsubscribe | undefined

  private _userAccounts_map = signal<Map<string, { profile: Profile }>>(new Map())

  userAccounts_map = this._userAccounts_map.asReadonly()
  userProfiles_map = computed(() => new Map(Array
    .from(this._userAccounts_map().entries())
    .map(([key, value]) => [key, value.profile])
  ))


  /*
    userProfiles = computed(() => ({
      ...this.allUserAccounts().reduce((accumulator: { [uid: string]: Profile }, userAccount) => {
        const uid = userAccount.userRecord?.uid
        if (uid) {
          return {
            ...accumulator,
            [uid]: userAccount.profile
          } as { [uid: string]: Profile }
        }
        return accumulator
      }, {})
    }))
  */

  constructor() {
    // this.allUserAccounts_Subscription()

    effect(() => {
      /*
            if (this.isUserAdmin()) {
              untracked(() => this.getUserRecords_FirebaseAuth())
            }
      */
      // if (this.profileService.idTokenResult()) {
      //   console.log("this.edit_FirebaseAuth([Role.ADMIN]) // force ADMIN role")
      //   this.getUserRecords_FirebaseAuth()
      //   this.getUserRecord_FirebaseAuth("Z5SgOn7YjFTnbaQs769x6jEYukE2")
      //   this.edit_FirebaseAuth([Role.ADMIN], "Z5SgOn7YjFTnbaQs769x6jEYukE2") // force ADMIN role
      // }
    })
    effect(() => {
      if (this.isModerator()) {
        untracked(() => this.allUserAccounts_Subscription())
      }
      if (!this.isModerator()) {
        if (this.allUserAccounts_Unsubscribe)
          this.allUserAccounts_Unsubscribe()
        this.allUserAccounts_Unsubscribe = undefined
        untracked(() => {
          // this.allUserAccounts.set([])
          this._userAccounts_map.set(new Map())
        })
      }
    })
  }

  /**
   * all userRecords from Firebase Authentication
   * mapped in cloud function, provides limited data, does not emit on changes
   */

    getUserRecords_FirebaseAuth() {
      return this.httpClient.get<{ userRecords: UserRecord[] }>(this.baseUrl)
        .subscribe(({ userRecords }) => {
          console.log(userRecords)
          // this.userRecords.set(userRecords)
        })
    }

  /**
   * one userRecord from Firebase Authentication
   * mapped in cloud function, provides limited data, does not emit on changes
   */
  getUserRecord_FirebaseAuth(uid: string) {
    return this.httpClient.get<{ userRecord: UserRecord }>(this.baseUrl + "/users/" + uid)
      .pipe(
        map(({ userRecord }) => userRecord)
      )
    // .subscribe(userRecord => {
    //   console.log(userRecord)
    // })
  }

  /*
    create_FirebaseAuth(displayName: string, password: string, email: string, roles: Role[]) {
      const url = this.baseUrl
      return this.httpClient
        .post(url, { displayName, password, email, roles })
        .subscribe()
    }
  */

  edit_FirebaseAuth(roles: Role[], userId: string) {
    const url = this.baseUrl + "/users/" + userId
    this.httpClient
      .patch(url, { roles, uid: userId })
      .subscribe({
        next: response => {
        },
        error: err => {
          // handle error response, very useful error message
          console.log("HTTP Error", err)
        }
      })
  }

  allUserAccounts_Subscription() {
    this.allUserAccounts_Unsubscribe = onSnapshot(
      collection(this.firestore, "user-accounts"),
      (snapshot) => {
        const userAccounts_map = new Map(this._userAccounts_map())
        snapshot.docChanges().forEach(change => {
          const userAccount = change.doc.data() as { profile: Profile }
          userAccounts_map.set(userAccount.profile.userId, userAccount)
        })
        /**
         * TODO: should we check the profile object (for userId, userName, userEmail) right away?
         */
        this._userAccounts_map.update(() => userAccounts_map)
      }
    )
  }

}
