import { Component } from "@angular/core"
import { RouterLink } from "@angular/router"
import { NgOptimizedImage } from "@angular/common"
import { UnderwritingManualDialogComponent } from "../../../dialogs/underwriting-manual.dialog.component"

@Component({
  imports: [
    RouterLink,
    NgOptimizedImage,
    UnderwritingManualDialogComponent,
  ],
  standalone: true,
  styles: [`
    .underwriting-link {
      margin: 0 auto;
      width: 200px;
      display: block;
      position: relative;
    }

    .underwriting-link .manual {
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(1);
    }

    .underwriting-link .manual {
      transition: all 0.3s ease;
    }

    .underwriting-link:hover .manual {
      top: calc(-10% / 2);
      left: calc(-10% / 2);
      transform: scale(1.1);
    }
  `],
  template: `
    <a
      [routerLink]="[]"
      fragment="underwriting-manual"
      queryParamsHandling="preserve"
      class="underwriting-link"
    >
      <img
        class="bg"
        [ngSrc]="manualButtonSrc"
        width="600"
        height="570"
        alt=""
        priority=""
      >
      <img
        class="manual"
        [ngSrc]="manualButtonLargeSrc"
        width="600"
        height="570"
        priority=""
        alt="A photocopy of the cover of the Federal Housing Administration's Underwriting Manual"
      >
    </a>
    <e2e-underwriting-manual-dialog/>
  `,
  selector: "e2e-underwriting-manual",
})
export class UnderwritingManualComponent {
  manualButtonSrc = "/assets/images/S2-Underwriting-Manual-Button-Bg.png"
  manualButtonLargeSrc = "/assets/images/S2-Underwriting-Manual-Button-LargeManual.png"
}
