import { Component, signal } from "@angular/core"
import { UnderwritingManualComponent } from "./underwriting-manual.component"

@Component({
  imports: [
    UnderwritingManualComponent,
  ],
  standalone: true,
  template: `
    <div style="padding: 0 80px">
      <div
        class="column"
        style="gap: 20px; border-radius: 10px; padding: 20px 30px"
        data-theme="redline_dark"
      >
        <e2e-underwriting-manual/>
        <div>
          Redlining manuals instructed surveyors to indicate “hazardous infiltration” which could include
          “negroes” or “foreign born populations.” This made race synonymous with property value. As time when
          on, particularly after WWII, demographics like Irish, Italians and Jewish were included as “white,”
          allowing them to access growing, homogeneous, middle class suburbs. People of color, including blacks
          and hispanics, were increasingly concentrated in Redlined areas.
        </div>
        <div>
          As the suburbs were first built, “racial covenants” and “deed restrictions” kept non-whites from being
          able to move to these new areas of investment. Eventually, this built the American landscape we are
          familiar with today: concentrated areas of poverty in diverse, often urban neighborhoods, with
          wealthier and Whiter suburbs surrounding them.
        </div>
        <div>
          This geographic structure means that vastly different services, schools, infrastructure, housing
          quality, policing and more remain largely determined by race and class, but only need to associate to
          zip codes. By the 1960s Civil Rights Acts, explicit racism was removed from most policy, and Redlining
          was made illegal. However, this structural geography largely remains today. It has never been undone.
        </div>
      </div>
    </div>
  `,
  selector: "e2e-intro-definitions-more",
})
export class IntroDefinitionsMoreComponent {
  activeIndex = signal<number | number[]>([])
}
