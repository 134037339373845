import { IntroSubContent } from "../components/pages/intro/intro.model"
import { Injectable } from "@angular/core"
import { RegionConfig } from "./region.model"

@Injectable({
  providedIn: "root",
})
export class DetroitMiRegion {

  config(): RegionConfig {
    return {
      audioTour: true,
      firewall: true,
    }
  }

  localVideo(): IntroSubContent {
    return {
      image: {
        src: "",
        style: {},
      },
      video: {},
      style: {},
      contents: [],
      subTitle: "",
      value: "",
    }
  }

  brandHeader(): IntroSubContent {
    return {
      contents: [],
      video: {},
      title: "Detroit",
      subTitle: "presented in partnership with",
      image: {
        altText: "",
        src: "assets/pages/intro/....png",
        style: {},
      },
      style: {
        width: "360px",
      },
    }
  }

}
